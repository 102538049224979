import React from 'react';
import "./app-link.scss";
import appStore from '../../assets/images/app-store.png'
import playStore from '../../assets/images/play-store.png'

class AppLink extends React.Component {


    render() {

        return (
            <div className="app-link">

                {
                    this.props.isVertical ?
                        <div className="app-image-large">
                            <div className="pt-2">
                                <a href="https://apps.apple.com/us/app/countingwell/id1508540117" target="_blank"><img src={appStore} /></a>
                            </div>
                            <div className=" pt-2">
                                <a href="https://play.google.com/store/apps/details?id=com.cwapp" target="_blank"><img src={playStore} /></a>
                            </div>
                        </div> :
                        <div className="app-image pt-2">

                            <div>
                                <div className="d-inline-block pr-2">
                                    <a href="https://apps.apple.com/us/app/countingwell/id1508540117" target="_blank"><img src={appStore} /></a>
                                </div>
                                <div className="d-inline-block">
                                    <a href="https://play.google.com/store/apps/details?id=com.cwapp" target="_blank"><img src={playStore} /></a>
                                </div>
                            </div>
                        </div>

                }

            </div>
        );
    }

}

export default AppLink;