
import ServerConfigs from './server-config';
const Urls = {
    BASE_URL: ServerConfigs.url,
    IS_LOGGEDIN: '/api/web/users/isloggedin',
    LOGOUT: '/api/web/users/logout',

    SEND_OTP: '/api/web/users/sendOtp',
    SEND_SINGUP_OTP: '/api/web/users/signup/otp',
    SEND_OTP_SCHOOL_COUPON_LOGIN: '/api/web/users/schoolcoupon/sendotp',
    VERIFY_OTP: '/api/web/users/verifyOtp',
    VERIFY_USER_OTP: '/api/web/users/verifyuserotp',

    VERIFY_SINGUP_OTP: "/api/web/users/signup/verify",
    SING_UP: "/api/web/users/signup",

    PAYMENT_HASH: '/api/web/users/payment/hash',
    SUBSCRIPTIONS: '/api/web/users/subscriptionplans/v2',
    SCHOLARSHIP_PAYMENT_HASH: "/api/web/users/schloarship/hash",
    COURSE_PAYMENT_HASH: "/api/web/users/course/hash",

    USER_LIST: "/api/web/users/list",
    COURSE_USER_LIST: "/api/web/users/course/list",
    SCHOLARSHIP: "/api/web/users/scholarship/discount",
    SCHOOL_USER_LIST: "/api/web/users/schooldiscount",
    SCHOOL_USER_CONNECT: "/api/web/users/school/connect",

    COUNTRY_LIST: "/api/country/list",
    CITIES_LIST: '/api/cities/list',

    MISCELLANEOUS: "/api/web/users/miscellaneous",
    MISCELLANEOUS_LIST: "/api/web/users/miscellaneous/list",

    PUBLIC_CONTENT_LIST: "/api/publiccontent/list",

    APPLY_COUPON: "/api/web/users/coupon/apply",

    COUPON_LIST: "/api/web/users/coupon/list",
    SCHOOL_COUPON: "/api/web/users/coupon/school",

    COURSE: "/api/web/users/course",
    COURSE_LIST: "/api/web/users/course/list/all"

};

export default Urls;
