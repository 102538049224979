import React from 'react';
import "./user-list.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import PaymentAPI from '../../../apis/payment'
import UsersAPI from '../../../apis/users'
import Divider from '../../../assets/images/divider.png'
import SubscribedImage from '../../../assets/images/subscribed-image.png'
import HeaderLogo from '../../../assets/images/logo.png'
import Ellipse from '../../../assets/images/ellipse.png'
import Bulb from '../../../assets/images/bulb.png'
import { Constants } from '../../../constants/constants'
import CouponApi from '../../../apis/coupon'

class UsersListSubscrption extends React.Component {
    planId = ""
    state = {
        subscriptionList: [],
        users: [],
        selectedUsers: [],
        subscription: {},
        showUserList: true,
    }

    constructor(props) {
        super(props)
    }

    componentDidMount() {

        let urlParams = new URLSearchParams(window.location.search);
        this.schoolCoupon = urlParams.get('scpn')
        this.schoolId = urlParams.get('sid')

        this.getCoupon()
        this.getUsers()
    }

    onSelectStudent(user) {
        if (user.isSubscribed) return
        let selectedUser = user._id
        this.setState({ selectedUser, user })
    }


    getUsers() {
        UsersAPI.getUsersForSchoolDiscount({ schoolId: this.schoolId }).then((response) => {
            let data = response.data
            if (data.length) {
                this.setState({ users: data })
            } else {
                this.setState({ users: [] })
            }
        }).catch((err) => {
            alert("Something went wrong");
        })
    }

    getCoupon() {

        CouponApi.getSchoolCoupons({ coupon: this.schoolCoupon, schoolId: this.schoolId }).then((response) => {

            let subscriptions = response.subscriptions || []
            let subscription = subscriptions[0]
            this.setState({ subscription, school: response.school, couponCode: response.couponCode })

        }).catch((err) => {
        })
    }

    onSubscribe = () => {

        if (!this.state?.user.schoolId) {
            this.setState({ showUserList: false })
            return
        }

        if (this.state.isCreatingHash) return
        if (!this.state.subscription || !this.state.subscription._id) return
        if (!this.state.selectedUser || this.state.disableContinue) return

        let payload = {
            subscriptionId: this.state.subscription._id,
            usersId: [this.state.selectedUser],
            couponId: this.schoolCoupon,
            coupon: this.state.couponCode,
            schoolId: this.schoolId
        }

        let validation = this.validateSubscriptionPayload(payload)
        if (!validation) return
        this.setState({ isCreatingHash: true })

        PaymentAPI.getHash(payload).then((response) => {
            let payload = response.data.hash
            if (payload) {
                this.setState({ showForm: true, payload, isCreatingHash: false, errorMessage: "" })
            }
        }).catch((err) => {
            if (err.response) {
                if (err.response.status === 900) {
                    this.setState({ errorMessage: "Invalid Coupon" })
                }
            }
            this.setState({ isCreatingHash: false, })
            alert("Something went wrong");
        })
    }

    validateSubscriptionPayload(payload = {}) {
        if (!payload.coupon) {
            this.setState({ errMessage: "Invalid Coupon" })
            return false
        }

        if (!payload.couponId) {
            this.setState({ errMessage: "Invalid Coupon" })
            return false
        }

        if (!payload.couponId) {
            this.setState({ errMessage: "Invalid Coupon" })
            return false
        }
        this.setState({ errMessage: "" })
        return true
    }

    onClickConnect = () => {
        let payload = {
            userId: this.state.selectedUser,
            schoolId: this.schoolId,
            verificationCode: this.state.verificationCode
        }
        UsersAPI.connectToSchool(payload).then((response) => {
            let user = this.state?.user
            user.schoolId = this.schoolId
            this.setState({ user }, () => {
                this.onSubscribe()
            })

        }).catch((err) => {
            if (err.response) {
                if (err.response?.data?.code === 900) {
                    this.setState({ isInvalidCoupon: true, errorMessage: "Invalid School Code" })
                }
            }
        })
    }

    submitForm() {
        setTimeout(() => {
            document.payuform.submit();
        }, 100)
    }

    onShowForm = (payload) => {
        return (
            <div style={{ "display": "none" }}>
                <form action={payload.actionUrl} name="payuform" id="payuform" method="POST" >
                    <input type="hidden" name="key" value={payload.key} />
                    <input type="hidden" name="hash" value={payload.hash} />
                    <input type="hidden" name="txnid" value={payload.txnid} />
                    <input type="hidden" name="amount" value={payload.amount} />
                    <input type="hidden" name="firstname" id="firstname" value={payload.firstname} />
                    <input type="hidden" name="email" id="email" value={payload.email} />
                    <input type="hidden" name="phone" value={payload.phone} />
                    <input type="hidden" name="productinfo" value={payload.productinfo} />
                    <input type="hidden" name="surl" size="64" value={payload.surl} />
                    <input type="hidden" name="furl" size="64" value={payload.furl} />
                    {payload.isRecurring ? <input type="hidden" name="api_version" value={payload?.api_version || 7} /> : ""}
                    {payload.isRecurring ? <input type="hidden" name="si" value={payload.si || 1} /> : ""}
                    {payload.isRecurring ? <input type="hidden" name="si_details" value={payload.si_details} /> : ""}
                    {payload.pg ? <input type="hidden" name="pg" value={payload.pg} /> : ""}
                    <input type="hidden" type="submit" value="Submit" onClick={this.submitForm()} />
                </form>
            </div>
        )
    }


    renderUserList() {
        return (
            <div>

                <div className='cw-card-header pt-4 text-center'>
                    <h5><b>Select Student </b></h5>
                    <div className="text-1 pt-2">{this.state.school?.name}, {this.state.school?.address?.branch}, {this.state.school?.address?.city}</div>
                </div>
                <div className="cw-card-body">
                    <div className="student-list">
                        {
                            this.state.users.map((user, index, userData) => (
                                <div className={`row student ${!user.isSubscribed ? "cursor-pointer" : ""} `} onClick={() => this.onSelectStudent(user)}>
                                    <div className="col flex overflow-hidden py-2">
                                        <div className="d-inline-block pr-3">

                                            {
                                                this.state.selectedUser === user._id ?
                                                    <FontAwesomeIcon color="green" icon={faCheckCircle} />
                                                    :
                                                    user.isSubscribed ?
                                                        <FontAwesomeIcon color="gray" icon={faCheckCircle} />
                                                        :
                                                        <img class="ellipse" src={Ellipse} />

                                            }
                                        </div>

                                        <div className="d-inline-block">
                                            <small><b>{user.name}</b></small><br />
                                            <small>{user.alternateBoard ? user.alternateBoard : user.board}, {user.class}</small><br />
                                            {user?.school?.name ? <small>{user?.school?.name || ""}, {user?.school?.address?.branch || ""}, {user?.school?.address?.city || ""}</small> : ""}
                                        </div>

                                    </div>
                                    {
                                        user.isSubscribed ?
                                            <div className="col-4 text-center p-0">
                                                <div className="subscribed d-inline-block">
                                                    <div className="subscribed-tag text-right"><img src={SubscribedImage} /></div>
                                                </div>
                                            </div>
                                            : ""
                                    }

                                    {
                                        index !== userData.length - 1 ?
                                            <img className="divider-image" src={Divider} /> : ""
                                    }
                                </div>

                            ))
                        }
                    </div>

                    {
                        this.state.errorMessage ? <div className="p-2 text-center"> <span className="error-message text-danger">{this.state.errorMessage}</span> </div> : ""
                    }

                    {
                        this.state.subscription && this.state.subscription._id ?
                            <div>
                                {
                                    this.state.users.length && this.state.users.some(user => !user.isSubscribed) ?
                                        <div className="text-center pt-5 mt-5">
                                            <div className="text-danger pb-2"><small> {this.state.errMessage || ""} </small></div>
                                            <span className={`cw-button btn btn-md ${!this.state.selectedUser || this.state.disableContinue ? "disable-subscribe-button" : ""}`} onClick={() => this.onSubscribe()}> CONTINUE</span>
                                        </div>
                                        : ""
                                }
                            </div>
                            : ""
                    }
                </div>
            </div>

        )
    }

    onChangeSchoolCode = (event) => {
        this.setState({ verificationCode: event?.target?.value, isInvalidCoupon: false })
    }

    renderSchoolConnect() {
        return (
            <div>

                <div className='cw-card-header pt-4 text-center'>
                    <h5><b>Connect Your School</b></h5>
                </div>
                <div className="cw-card-body">
                    <div className="text-1 pt-2 text-center">SCHOOL CODE</div>
                    <div className="pt-2">
                        <input className={`cw-input ${this.state.isInvalidCoupon ? "invalid" : ""}`} placeholder="Enter your School code" onChange={this.onChangeSchoolCode} value={this.state.verificationCode} />
                        {
                            this.state.isInvalidCoupon ? <div className=" text-center text-1 text-danger">{this.state.errorMessage}</div> : ""
                        }
                    </div>
                    <div className="pt-4">
                        <div className="note">
                            <div className="pl-2 pt-2">
                                <img className="bulb-img" src={Bulb} />
                            </div>
                            <div className="text-1">
                                Enter a code only for your School since you will be linked to that particular school on our App. In case you don't have a code, Please check with your Class Teacher.
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="text-center pt-5 mt-5">
                            <div className="text-1 pb-2"> {this.state.school?.name}, {this.state.school?.address?.branch}, {this.state.school?.address?.city}</div>
                            <span className={`cw-button btn btn-md ${this.state.verificationCode?.length >= 7 ? "" : "disable-subscribe-button"}`} onClick={this.onClickConnect}> CONTINUE</span>
                        </div>
                    </div>

                </div>
            </div>
        )
    }

    render() {

        return (

            <div className="user-list">
                <div className="user-subscription-background">
                    <div className='header row no-gutters'>
                        <div className="col-6"> <img className="logo" src={HeaderLogo} /></div>
                    </div>
                    {this.state.showForm ? this.onShowForm(this.state.payload) : ""}
                    <div className="parent-box pt-5">
                        <div className="child-box">
                            <div className="cw-card  cw-card-size">
                                {
                                    this.state.showUserList ? this.renderUserList() : this.renderSchoolConnect()
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }

}

export default UsersListSubscrption;