import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import SessionCache from './session-cache';
import Login from '../login/login-v2';
import AuthenticateRoute from './authenticate-route';
import Welcome from '../welcome/welcome';
import Path from './routes-path';

let public_routes = [
    Path.LOGIN,
    Path.SIGN_UP,
    Path.PAYMENT_SUCCESS,
    Path.PAYMENT_FAIL,
    Path.SCHOLARSHIP,
    Path.EXAM_PREP_KIT_SCHOLARSHIP,
    Path.SCHOOL_COUPON_OFFER,
    Path.SCHOOL_COUPON_LOGIN,

];

export default function RouteWrapper({
    component: Component,
    isPrivate,
    ...rest
}) {

    if (public_routes.indexOf(rest.path) != -1) {
        return <Route {...rest} component={Component} />;
    }

    let status = SessionCache.isAuthenticated();
    if (status == null) {
        return <Route {...rest} render={(props) => {
            return <AuthenticateRoute {...rest} component={Component}></AuthenticateRoute>
        }} />
    }
    if (SessionCache.isVerified() == 'false') {
        return <Route location={Path.WELCOME} component={Welcome} />
    }
    else if (status) {
        return <Route {...rest} component={Component} />;
    }

    return <Route path={Path.LOGIN} component={Login} />
}

RouteWrapper.propTypes = {

    isPrivate: PropTypes.bool,
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired
};

RouteWrapper.defaultProps = {

    isPrivate: false
}