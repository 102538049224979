import React from 'react';
import "./subscription-amount.scss";
import Divider from '../../../../assets/images/divider.png'

class Coupon extends React.Component {

    state = {
        isInvalidCoupon: false
    }

    datePipe(givenDate, isTime = false) {
        givenDate = new Date(givenDate).toString()
        let [day, month, date, year, time] = givenDate.split(' ')
        return `${date} ${month}, ${year} ${isTime ? time.substr(0, 5) : ""}`

    }

    onChangeHandler(event) {
        this.setState({ isInvalidCoupon: false })
    }

    getFormattedAmount(amount) {
        return amount ? amount.toLocaleString('en') : amount
    }

    getAppoxText() {
        if (this.props.currency == "₹") return ""
        let amount = this.props.discountedPrice - this.props.couponAmount
        const countryPrice = Number(this.props.myCountryPrice * amount).toFixed(2)
        const indiaPrice = Number(this.props.indiaAmount * amount).toFixed(2)
        return `( Approximately ${countryPrice} ${this.props.myCurrency} )`
    }

    renderAppliedCoupon() {
        return (
            <div className="py-2">
                {
                    this.props.couponAmount > 0 ?
                        <div>
                            <div className="applied-coupon">
                                <div className="d-inline-block">
                                    <div className="flex">
                                        <span className="discount-text pr-1">Discount  ( {this.props.discount} ) </span>
                                    </div>
                                </div>

                                <div className="d-inline-block pl-3">
                                    <span className="coupon-amt"> - {this.props.currency} {this.getFormattedAmount(this.props.couponAmount)}</span>
                                </div>
                            </div>

                            <div>
                                <img className="divider-img" src={Divider} alt="" />
                            </div>

                            <div className="pb-2">
                                <span className="payable-amount">
                                    {this.props.currency} {this.getFormattedAmount(this.props.discountedPrice - this.props.couponAmount)}
                                </span>
                            </div>
                            <div className="text-center">
                                <div className="my-country-price text-center pt-2">
                                    {
                                        this.props.myCountryPrice ? this.getAppoxText() : ""
                                    }
                                </div>
                            </div>
                        </div>
                        : ""}
            </div>
        )
    }

    render() {

        return (
            <div className="subscription-amount text-center">
                <div className="header-name pt-4">{this.props.name}</div>
                <div className="pt-2 valid-till"> <span>{this.props.subTitle}</span></div>
                <div className="d-inline-block pt-3">
                    <span className="amt-strike">
                        <span className="amount">{this.props.currency} {this.getFormattedAmount(this.props.discountedPrice)}</span>
                    </span>
                </div>
                {
                    this.renderAppliedCoupon()
                }
            </div>
        );
    }

}

export default Coupon;