import { ImageConstants } from '../constants/constants';


export function getImageSource(image) {

    let imageSrc;

    switch (image) {
        case ImageConstants.APP_DOWNLOAD_QR_CODE:
            imageSrc = require('../assets/images/app_download_qr_code.jpg');
            break;

        case ImageConstants.DOWN_CARET_DROPDOWN:
            imageSrc = require('../assets/images/down-caret-light.png');
            break;
            

        case ImageConstants.CW_LOGO:
            imageSrc = require('../assets/images/cw-logo.png');
            break;

        case ImageConstants.CW_IMAGE:
            imageSrc = require('../assets/images/cw-image.png');
            break;

        case ImageConstants.WELCOME:
            imageSrc = require('../assets/images/welcome.png');
            break;

        case ImageConstants.PERFORMANCE_REPORT:
            imageSrc = require('../assets/images/performance.png');
            break;

        case ImageConstants.ELO_SUMMARY_REPORT:
            imageSrc = require('../assets/images/elo-summary.png');
            break;

        case ImageConstants.ELO_PERFORMANCE_BY_STUDENTS:
            imageSrc = require('../assets/images/student-performance.png');
            break;

        case ImageConstants.STREAMING_REPORT:
            imageSrc = require('../assets/images/streaming-report.png');
            break;

        case ImageConstants.HEADER_LOGO:
            imageSrc = require('../assets/images/header-logo.png');
            break;

        case ImageConstants.USER_PLACEHOLDER:
            imageSrc = require('../assets/images/user.png');
            break;

        case ImageConstants.LOGOUT:
            imageSrc = require('../assets/images/logout.png');
            break;

        case ImageConstants.REMOVE_CIRCLE:
            imageSrc = require('../assets/images/remove-circle.png');
            break;

        case ImageConstants.FORGOT_PASSWORD:
            imageSrc = require('../assets/images/forgot-password.png');
            break;

        case ImageConstants.CLS_PERFORMANCE_AVG_ATTEMPTED_PER_ELO:
            imageSrc = require('../assets/images/average-attempt_2x.png');
            break;

        case ImageConstants.CLS_PERFORMANCE_AVG_TIME_PER_ELO:
            imageSrc = require('../assets/images/average-time_2x.png');
            break;

        case ImageConstants.CLS_PERFORMANCE_ELOS_ATTEMPTED:
            imageSrc = require('../assets/images/attempted-elos_2x.png');
            break;

        case ImageConstants.CLS_PERFORMANCE_STUDENT_ATTEMPTED:
            imageSrc = require('../assets/images/students-attempted_2x.png');
            break;

        case ImageConstants.CLS_PERFORMANCE_TOTAL_ELOS:
            imageSrc = require('../assets/images/total-elos_2x.png');
            break;

        case ImageConstants.CLS_PERFORMANCE_TOTAL_STUDENTS:
            imageSrc = require('../assets/images/total-students_2x.png');
            break;

        case ImageConstants.DROPDOWN_ARROW:
            imageSrc = require('../assets/images/dropdown-arrow.png');
            break;

        case ImageConstants.REPORT_BG_1:
            imageSrc = require('../assets/images/report-background1.png');
            break;

        case ImageConstants.REPORT_BG_2:
            imageSrc = require('../assets/images/report-background2.png');
            break;

        case ImageConstants.LOADER_HEADER:
            imageSrc = require('../assets/images/loader-header.png');
            break;

        case ImageConstants.LOADER_FOOTER:
            imageSrc = require('../assets/images/loader-footer.png');
            break;

        case ImageConstants.LOADING_GIF:
            imageSrc = require('../assets/images/loading-gif/loading-gif.gif');
            break;

        case ImageConstants.PROFILE_VECTOR:
            imageSrc = require('../assets/images/profile-vector.png');
            break;

        case ImageConstants.REPORT_WELCOME_ICON:
            imageSrc = require('../assets/images/teaching-quote.png');
            break;

        case ImageConstants.EXCEL_ICON:
            imageSrc = require('../assets/images/excel.png');
            break;

        case ImageConstants.CLEAR_ICON:
            imageSrc = require('../assets/images/clear.png');
            break;

        case ImageConstants.HR_LINE:
            imageSrc = require('../assets/images/line.png');
            break;
    }

    return imageSrc;
}