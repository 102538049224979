import React from 'react';
import "./sign-up.scss";
import EditIcon from '../../assets/images/edit-icon.png'
import CurveTop from '../../assets/images/curves-top.png'
import CurveBottom from '../../assets/images/Curves-Bottom.png'
import RadioActive from '../../assets/images/radio-active.png'
import RadioInActive from '../../assets/images/radio-inactive.png'
import BoyActive from '../../assets/images/gender-boy-active.png'
import GirlActive from '../../assets/images/gender-girl-active.png'
import BoyInActive from '../../assets/images/gender-boy-inactive.png'
import GirlINActive from '../../assets/images/gender-girl-inactive.png'
import SessionCache from '../routes/session-cache';
import HeaderLogo from '../../assets/images/logo.png'
import AuthenticateAPI from '../../apis/authenticate'
import { validateMobileNumber } from '../../utility/common-utilities'
import CryptoJS from "crypto-js"
import Path from '../routes/routes-path';
import { Redirect } from 'react-router';

class Singup extends React.Component {
    state = {
        type: "REGISTER",
        selectedCountry: { dialCode: "+91", length: 10, name: "India" },
        isOtpSent: false,
        dot: "",
        isOtpVerified: false
    }

    coutryCodeHash = {}
    cityHash = {}
    courseId = ""

    constructor(props) {
        super(props)

        let urlParams = new URLSearchParams(window.location.search);
        this.courseId = urlParams.get("course")
    }
    componentDidMount() {
        this.getCities()
    }


    getCountryCode(payload = {}) {
        AuthenticateAPI.getCountyList(payload).then((response) => {
            let data = response.response
            if (data.length) {
                this.setState({ countryList: data })
                this.buildCountryCodeDropDown(data)
            } else {
                this.setState({ countryList: [], countryCodeDropDown: [] })
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    buildCountryCodeDropDown(list = []) {
        let countryCodeDropDown = []
        list.map(item => {
            this.coutryCodeHash[item._id] = item
            countryCodeDropDown.push({ text: item.dialCode, addOn: item.name, value: item._id })
        })
        this.setState({ countryCodeDropDown })
    }

    getCities = (searchText) => {
        let payload = {
            limit: 100
        }

        if (this.state.selectedCountry?.dialCode) {
            payload['countryCode'] = encodeURIComponent(this.state.selectedCountry?.dialCode)
        }


        if (this.state.country) {
            payload['country'] = this.state.country
        }

        if (searchText) {
            payload.name = `/${searchText}`
        }

        AuthenticateAPI.getCities(payload).then((response) => {
            let data = response.response
            let cityDropdown = []
            for (let i = 0; i < data.length; i++) {
                cityDropdown.push({ title: data[i].name, value: data[i].name })
                this.cityHash[data[i].name] = data[i]
            }
            this.cityHash["Other"] = { state: "Other" }
            cityDropdown.push({ title: "Other", value: "Other" })

            this.setState({ cityDropdown })
        }).catch((err) => {
            console.log(err)
        })


        AuthenticateAPI.getCities(payload, (err, response) => {
            if (err) {
                return;
            }
            this.setState({ cities: response.response, city: searchText })
        })
    }

    openCountryCode() {
        if (this.state.isOtpSent) return
        this.getCountryCode()
        this.setState({ showDropdown: !this.state.showDropdown })
    }

    onSelectType(type) {
        this.setState({ type, mobileNumber: "", otpBox1: "", otpBox2: "", otpBox3: "", otpBox4: "", isSubmit: false, isOtpSent: false })
    }

    onSelectCode(item) {
        this.setState({ selectedCountry: this.coutryCodeHash[item.value], showDropdown: false }, () => {
            this.setState({ isLoginEnable: this.validateMobile(this.state.mobileNumber) })
            this.getCities()
        })

    }

    onSelectCity(item) {
        this.setState({ city: item.value, showCitiesDropdown: false })
    }

    onSearch(event) {
        let search = event.target.value
        this.getCountryCode({ name: `/${search}`, })
    }

    onSearchCity(event) {
        let search = event.target.value
        this.getCities(search)
    }

    onChangeHandler = (event, key) => {
        if (key === "mobileNumber") {

            if (!this.allowOnlyNumbers(event.target.value)) return

            this.setState({
                [key]: event.target.value,
                isInvalidMobile: false,
                mobileNotRegistered: false,
                isLoginEnable: this.validateMobile(event.target.value)
            });
        } else {
            this.setState({ [key]: event.target.value },);
        }
    }

    allowOnlyNumbers(value) {
        const re = /^[0-9\b]+$/;

        if (value === '' || re.test(value)) {
            return true
        }
        return false
    }


    validateMobile(value) {

        if (value) {
            let validate = validateMobileNumber(value, this.coutryCodeHash[this.state?.selectedCountry?._id] || this.state.selectedCountry)
            return validate
        }
        return false
    }


    sendSingupOtp() {

        const mobileNumber = this.state.mobileNumber
        const countryCode = this.state.selectedCountry.dialCode

        let hashKey = CryptoJS.AES.encrypt('COUNTINGWELL_STUDENT_SIGN_UP_RAVI_NIRMAL', 'COUNTINGWELL@123_RAVI_NIRMAL').toString();

        if (!this.state.name || !this.state.name.trim()) {
            this.setState({ invalidName: true })
            return
        } else {
            this.setState({ invalidName: false })
        }

        if (!this.validateMobile(this.state.mobileNumber)) {
            this.setState({ isInvalidMobile: true });
            return;
        } else {
            this.setState({ isInvalidMobile: false })
        }

        this.setState({ isSubmit: true });

        const loadingIntervel = setInterval(() => {
            if (this.state.dot?.length === 3) {
                this.setState({ dot: `.` })
            } else {

                this.setState({ dot: `${this.state.dot}.` })
            }
        }, 500)

        if (mobileNumber && countryCode) {

            let payload = { mobileNumber, countryCode, hashKey, name: this.state.name };

            AuthenticateAPI.sendSignupOtp(payload).then((userDetails) => {

                this.setState({ isOtpSent: true, isLoginEnable: false, isSubmit: false, dot: "", otpBox1: "", otpBox2: "", otpBox3: "", otpBox4: "", invalidName: false, userNameExsist: false, isInvalidMobile: false });
                clearInterval(loadingIntervel)
                this.startResendTimer()
            }).catch((err) => {
                clearInterval(loadingIntervel)
                if (err.response?.data?.code === 11000) {
                    this.setState({ userNameExsist: true })
                }
                this.setState({ isSubmit: false, dot: "" })
            })
        }
    }

    verifySignupOtp() {
        const mobileNumber = this.state.mobileNumber
        const countryCode = this.state.selectedCountry.dialCode
        const otp = `${this.state.otpBox1 || ''}${this.state.otpBox2 || ''}${this.state.otpBox3 || ''}${this.state.otpBox4 || ''}`
        if (!this.state.mobileNumber || !otp || otp.length !== 4) {
            this.setState({ isInvalidOtp: true })
            return;
        }

        this.setState({ isSubmit: true });

        const loadingIntervel = setInterval(() => {
            if (this.state.dot.length === 3) {
                this.setState({ dot: `.` })
            } else {

                this.setState({ dot: `${this.state.dot}.` })
            }
        }, 500)

        if (mobileNumber && otp) {

            let payload = { mobileNumber, otp, countryCode, name: this.state.name };

            AuthenticateAPI.verifySignupOtp(payload).then((userDetails) => {
                clearInterval(loadingIntervel)
                SessionCache.user = userDetails.data
                this.setState({ isOtpVerified: true, isSubmit: false, isInvalidOtp: false })
            }).catch((err) => {
                clearInterval(loadingIntervel)
                this.setState({ isOtpVerified: false, dot: "", isSubmit: false, isInvalidOtp: true })
                alert("Invalid credentials");
            })
        }

    }

    signup() {
        const mobileNumber = this.state.mobileNumber
        const countryCode = this.state.selectedCountry.dialCode
        const otp = `${this.state.otpBox1 || ''}${this.state.otpBox2 || ''}${this.state.otpBox3 || ''}${this.state.otpBox4 || ''}`

        this.setState({ isSubmit: true });

        let cityInfo = this.cityHash[this.state.city]

        let payload = {
            mobileNumber,
            countryCode,
            otp,
            name: this.state.name,
            nickName: this.state.nickName,
            gender: this.state.gender,
            city: this.state.city,
            state: cityInfo?.state,
            timeZone: cityInfo?.timeZone,
            country: this.state.selectedCountry?.name
        }

        if (!this.validatePayload(payload)) return

        const loadingIntervel = setInterval(() => {
            if (this.state.dot.length === 3) {
                this.setState({ dot: `.` })
            } else {

                this.setState({ dot: `${this.state.dot}.` })
            }
        }, 500)


        AuthenticateAPI.signup(payload).then((userDetails) => {
            clearInterval(loadingIntervel)
            SessionCache.user = userDetails.data
            this.setState({ isLoginedIn: true })
        }).catch((err) => {
            clearInterval(loadingIntervel)
            this.setState({ isLoginFailed: true, dot: "" })
            alert("Invalid credentials");
        })

    }

    validatePayload(payload) {
        if (!payload.nickName) return false
        if (!payload.city) return false
        if (!payload.gender) return false

        return true
    }

    resendSignupOtp() {
        this.sendSingupOtp()
        this.setState({ time: 120 }, () => {
            this.resendIntervel = setInterval(() => {
                this.reduceTime(this.state.time - 1)
            }, 1000)
        })
    }

    sendOtp() {

        const mobileNumber = this.state.mobileNumber
        const countryCode = this.state.selectedCountry.dialCode
        if (!this.validateMobile(this.state.mobileNumber)) {
            this.setState({ isInvalidMobile: true });
            return;
        } else {
            this.setState({ isInvalidMobile: false });
        }

        this.setState({ isSubmit: true });

        const loadingIntervel = setInterval(() => {
            if (this.state.dot.length === 3) {
                this.setState({ dot: `.` })
            } else {

                this.setState({ dot: `${this.state.dot}.` })
            }
        }, 500)

        if (mobileNumber && countryCode) {

            let payload = { mobileNumber, countryCode };

            AuthenticateAPI.sendOtp(payload).then((userDetails) => {

                this.setState({ isOtpSent: true, isLoginEnable: false, isSubmit: false, dot: "", otpBox1: "", otpBox2: "", otpBox3: "", otpBox4: "" });
                clearInterval(loadingIntervel)
                this.startResendTimer()
            }).catch((err) => {
                clearInterval(loadingIntervel)
                this.setState({ isSubmit: false, dot: "", mobileNotRegistered: true })
            })
        }
    }

    verifyOtp() {
        const mobileNumber = this.state.mobileNumber
        const countryCode = this.state.selectedCountry.dialCode
        const otp = `${this.state.otpBox1 || ''}${this.state.otpBox2 || ''}${this.state.otpBox3 || ''}${this.state.otpBox4 || ''}`
        if (!this.state.mobileNumber || !otp || otp.length !== 4) {
            this.setState({ isInvalidOtp: true })
            return;
        }

        this.setState({ isSubmit: true });

        const loadingIntervel = setInterval(() => {
            if (this.state.dot.length === 3) {
                this.setState({ dot: `.` })
            } else {

                this.setState({ dot: `${this.state.dot}.` })
            }
        }, 500)

        if (mobileNumber && otp) {

            let payload = { mobileNumber, otp, countryCode };

            AuthenticateAPI.verifyOtp(payload).then((userDetails) => {
                clearInterval(loadingIntervel)
                SessionCache.user = userDetails.data
                this.setState({ isLoginedIn: true, dot: "" })
            }).catch((err) => {
                clearInterval(loadingIntervel)
                this.setState({ isLoginFailed: true, dot: "" })
                alert("Invalid credentials");
            })
        }

    }


    resendOtp() {
        this.sendOtp()
        this.startResendTimer()
    }

    startResendTimer() {
        if (this.state.time) {
            this.setState({ time: 120 })
        } else {
            this.setState({ time: 120 }, () => {
                this.resendIntervel = setInterval(() => {
                    this.reduceTime(this.state.time - 1)
                }, 1000)
            })
        }
    }

    reduceTime(time) {
        this.setState({ time })
        if (!time) return clearInterval(this.resendIntervel)
    }


    onKeyUpEvent(index, event) {
        const eventOtp = event.which || event.keyCode;
        if (this.getOtpBoxElement(index).value.length === 1) {
            if (index !== 4) {
                this.getOtpBoxElement(index + 1).focus()
                this.getOtpBoxElement(index + 1).select();
            } else {
                this.getOtpBoxElement(index).blur();
            }
        }
        if (eventOtp === 8 && index !== 1) {
            this.getOtpBoxElement(index - 1).focus()
            this.getOtpBoxElement(index - 1).select();
        }
    }

    onFocusEvent(index) {
        if (this.state.isLoginFailed || this.state.isInvalidOtp) {
            this.setState({ isLoginFailed: false, isInvalidOtp: false })
        }
        for (let item = 1; item < index; item++) {
            const currentElement = this.getOtpBoxElement(item);
            if (!currentElement.value) {
                currentElement.focus();
                currentElement.select()
                break;
            }
        }
    }

    onCLickGender(gender) {
        this.setState({ gender })
    }

    showCityDropdown = () => {
        this.setState({ showCitiesDropdown: !this.state.showCitiesDropdown })
    }

    getOtpBoxElement(index) {
        return document.getElementById('otpBox' + index);
    }

    onMobileNumberEdit() {
        this.setState({ mobileNumber: "", isOtpSent: false, time: 0, isLoginEnable: false, isInvalidOtp: false })
        clearInterval(this.resendIntervel)
    }

    countryCodeDropDown(dropDownList = []) {
        return (
            <div className="country-code-drop-down" id="st-3">
                <div className="p-2">
                    <div className="search">
                        <input className="form-control" placeholder={`Search`} value={this.state.search} onChange={(event) => { this.onSearch(event) }} />
                    </div>
                    <div>
                        {dropDownList.length ?
                            dropDownList.map(dropdown => (
                                <div className="dropdown-list cursor-pointer px-3 py-1" onClick={() => { this.onSelectCode(dropdown) }}>
                                    <div className="row country-name cursor-pointer">
                                        <div className="col-8"> {dropdown.addOn}</div>
                                        <div className="col text-right">  {dropdown.text}</div>
                                    </div>
                                </div>

                            ))
                            : <div className="text-center py-4 no-data">
                                <label> No Records found</label>
                            </div>
                        }</div>
                </div>
            </div>
        )
    }

    cityCodeDropDown(dropDownList = []) {
        return (
            <div className="country-code-drop-down" id="st-3">
                <div className="p-2">
                    <div className="search">
                        <input className="form-control" placeholder={`Search`} value={this.state.search} onChange={(event) => { this.onSearchCity(event) }} />
                    </div>
                    <div>
                        {dropDownList.length ?
                            dropDownList.map(dropdown => (
                                <div className="dropdown-list cursor-pointer px-3 py-1" onClick={() => { this.onSelectCity(dropdown) }}>
                                    <div className="row country-name cursor-pointer">
                                        <div className="col">{dropdown.title}</div>
                                    </div>
                                </div>

                            ))
                            : <div className="text-center py-4 no-data">
                                <label> No Cities found</label>
                            </div>
                        }</div>
                </div>
            </div>
        )
    }

    renderOtpInput() {
        return (
            <div className="otp-boxes">
                <input id="otpBox1" className={`${this.state.isLoginFailed || this.state.isInvalidOtp ? 'invalid' : ''}`} type="text" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(1, event)} onFocus={() => this.onFocusEvent(1)} onChange={(e) => this.onChangeHandler(e, 'otpBox1')} value={this.state.otpBox1} />
                <input id="otpBox2" className={`${this.state.isLoginFailed || this.state.isInvalidOtp ? 'invalid' : ''}`} type="text" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(2, event)} onFocus={() => this.onFocusEvent(2)} onChange={(e) => this.onChangeHandler(e, 'otpBox2')} value={this.state.otpBox2} />
                <input id="otpBox3" className={`${this.state.isLoginFailed || this.state.isInvalidOtp ? 'invalid' : ''}`} type="text" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(3, event)} onFocus={() => this.onFocusEvent(3)} onChange={(e) => this.onChangeHandler(e, 'otpBox3')} value={this.state.otpBox3} />
                <input id="otpBox4" className={`${this.state.isLoginFailed || this.state.isInvalidOtp ? 'invalid' : ''}`} type="text" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(4, event)} onFocus={() => this.onFocusEvent(4)} onChange={(e) => this.onChangeHandler(e, 'otpBox4')} value={this.state.otpBox4} />
            </div>)
    }


    renderSignupStep1() {
        return (
            <div>
                <div>
                    <label className="pb-1 cw-input-lable">
                        Name
                    </label>
                    <div>
                        <input className={`cw-input fixed-height ${this.state.invalidName || this.state.userNameExsist ? 'invalid' : ''}`}
                            placeholder='Enter Student’s Full Name'
                            onChange={(e) => this.onChangeHandler(e, 'name')}
                            onBlur={(event) => this.validateMobile(event.target.value)}
                            onFocus={this.onFocusInput}
                            value={this.state.name ? this.state.name : ""}
                        />
                    </div>
                    {this.state.userNameExsist ? <span className='invalid-text'>User name already exist</span> : ''}
                </div>

                <div className="pt-3 mb-1">
                    <label className="pb-1 cw-input-lable">
                        Mobile Number
                    </label>

                    <div className={`mobile-number-wrapper ${this.state.isInvalidMobile ? 'invalid' : ''}`}>

                        <div className="mobile-number">
                            <span className={`px-3 country-code ${this.state.isOtpSent ? "cursor: default" : "cursor-pointer"}`} onClick={() => this.openCountryCode()}>
                                <strong> {this.state.selectedCountry?.dialCode} </strong>
                            </span>
                            <input className="cw-input number"
                                placeholder='Enter mobile number'
                                onChange={(e) => this.onChangeHandler(e, 'mobileNumber')}
                                onBlur={(event) => this.validateMobile(event.target.value)}
                                onFocus={this.onFocusInput}
                                value={this.state.mobileNumber ? this.state.mobileNumber : ""}
                                disabled={this.state.isOtpSent} />
                            {this.state.isOtpSent ?
                                <span className="float-right mr-4 mt-2">
                                    <img className="cursor-pointer" onClick={() => this.onMobileNumberEdit()} src={EditIcon} />
                                </span> : ""
                            }

                        </div>
                    </div>

                    {(this.state.isSubmit && !this.state.mobileNumber) || this.state.isInvalidMobile ? <span className='invalid-text'>Please enter valid mobile number</span> : ''}

                </div>

                <div className="abs-div" onMouseLeave={() => this.openCountryCode()}>
                    {this.state.showDropdown ? this.countryCodeDropDown(this.state.countryCodeDropDown) : ""}
                </div>

                <div>
                    {this.state.isOtpSent ?
                        <div>
                            <div className="pt-3 mb-1">
                                <label className="cw-input-lable">
                                    OTP
                                </label>
                                {this.renderOtpInput()}
                                {this.state.isLoginFailed || this.state.isInvalidOtp ? <span className='invalid-text'>Enter valid otp</span> : ''}
                            </div>
                            <div className="">{
                                this.state.time ?
                                    <span className="resent-otp">Resend OTP in {this.state.time}</span>
                                    : <span className="resent-otp otp-color" onClick={() => this.resendSignupOtp()} >Resend OTP </span>
                            }
                            </div>
                        </div>
                        : ""}

                </div>
                <div className="pt-4">
                    {this.state.isOtpSent ?
                        <button className="btn btn-lg btn-block btn-primary border-radius mt-4 mb-3" onClick={() => this.verifySignupOtp()}>
                            VERIFY MOBILE NO.
                        </button> :
                        <div>
                            <div className="text-center"><span className="text-2">By registering I agree to the </span> <a target="_blank" href="https://countingwell.com/terms-of-service.html"><span className="tc cursor-pointer">terms and conditions</span> </a></div>
                            <button className="btn btn-lg btn-block btn-primary border-radius mt-1 mb-3" onClick={() => this.sendSingupOtp()}>
                                REGISTER
                            </button>
                        </div>
                    }
                </div>
            </div>
        )
    }

    renderSignupStep2() {

        return (
            <div>
                <div>

                    <label className="pb-1 cw-input-lable">
                        Student's Gender
                    </label>
                    <div>
                        <img className="mr-4 gender-image cursor-pointer" alt="" src={this.state.gender == "F" ? GirlActive : GirlINActive} onClick={() => this.onCLickGender("F")} />
                        <img className="gender-image cursor-pointer" alt="" src={this.state.gender == "M" ? BoyActive : BoyInActive} onClick={() => this.onCLickGender("M")} />
                    </div>
                </div>

                <div className="pt-4">
                    <label className={`pb-1 cw-input-lable`}>
                        Nick Name
                    </label>
                    <div>
                        <input className={`cw-input fixed-height  ${this.state.isSubmit && !this.state.nickName ? "invalid" : ""}`}
                            placeholder='Enter Student’s Nick Name'
                            onChange={(e) => this.onChangeHandler(e, 'nickName')}
                            onFocus={this.onFocusInput}
                            value={this.state.nickName ? this.state.nickName : ""}
                        />
                    </div>
                </div>

                <div className="pt-4">
                    <label className="pb-1 cw-input-lable">
                        City
                    </label>
                    <div>
                        <div onClick={this.showCityDropdown} className="">
                            <input className={`cw-input fixed-height cursor-pointer ${this.state.isSubmit && !this.state.city ? "invalid" : ""}`}
                                placeholder='Select City'
                                value={this.state.city ? this.state.city : ""}
                                disabled
                            /></div>

                        <div className="abs-div" onMouseLeave={this.showCityDropdown}>
                            {this.state.showCitiesDropdown ? this.cityCodeDropDown(this.state.cityDropdown) : ""}
                        </div>
                    </div>
                </div>

                <div className="pt-4">
                    <button className="btn btn-lg btn-block btn-primary border-radius my-3" onClick={() => this.signup()}>
                        CONTINUE
                    </button>
                </div>
            </div>
        )
    }

    renderSignup() {
        return (

            <div className="login-signup-form">
                {
                    !this.state.isOtpVerified ?
                        this.renderSignupStep1() : this.renderSignupStep2()
                }

            </div>
        )
    }


    renderSignIn() {
        return (

            <div className="login-signup-form">

                <div className="mb-1">
                    <label className="pb-1 cw-input-lable">
                        Mobile Number
                    </label>

                    <div className={`mobile-number-wrapper ${(this.state.isInvalidMobile && this.state.mobileNumber) ||
                        (this.state.isSubmit && !this.state.mobileNumber) ? 'invalid' : ''}`}>

                        <div className="mobile-number">
                            <span className={`px-3 country-code ${this.state.isOtpSent ? "cursor: default" : "cursor-pointer"}`} onClick={() => this.openCountryCode()}>
                                <strong> {this.state.selectedCountry?.dialCode} </strong>
                            </span>
                            <input className="cw-input number"
                                placeholder='Enter mobile number'
                                onChange={(e) => this.onChangeHandler(e, 'mobileNumber')}
                                onBlur={(event) => this.validateMobile(event.target.value)}
                                onFocus={this.onFocusInput}
                                value={this.state.mobileNumber ? this.state.mobileNumber : ""}
                                disabled={this.state.isOtpSent} />
                            {this.state.isOtpSent ?
                                <span className="float-right mr-4 mt-2">
                                    <img alt="" className="cursor-pointer" onClick={() => this.onMobileNumberEdit()} src={EditIcon} />
                                </span> : ""
                            }

                        </div>
                    </div>

                    {this.state.isInvalidMobile ? <span className='invalid-text'>Please enter valid mobile number</span> : ''}
                    {this.state.mobileNumber && this.state.mobileNotRegistered ? <span className='invalid-text'>This mobile number is not registered with countingwell</span> : ''}
                </div>

                <div className="abs-div" onMouseLeave={() => this.openCountryCode()}>
                    {this.state.showDropdown ? this.countryCodeDropDown(this.state.countryCodeDropDown) : ""}
                </div>

                <div>
                    {this.state.isOtpSent ?
                        <div>
                            <div className="pt-3 mb-1">
                                <label className="cw-input-lable">
                                    OTP
                                </label>
                                {this.renderOtpInput()}
                                {this.state.isLoginFailed || this.state.isInvalidOtp ? <span className='invalid-text'>Enter valid otp</span> : ''}
                            </div>
                            <div className="">{
                                this.state.time ?
                                    <span className="resent-otp">Resend OTP in {this.state.time}</span>
                                    : <span className="resent-otp otp-color" onClick={() => this.resendOtp()} >Resend OTP </span>
                            }
                            </div>
                        </div>
                        : ""}

                </div>
                <div className="pt-4">
                    {this.state.isOtpSent ?
                        <button className="btn btn-lg btn-block btn-primary border-radius mt-4 mb-3" onClick={() => this.verifyOtp()}>
                            VERIFY MOBILE NO.
                        </button> :
                        <div>
                            {/* <div className="text-center"><span className="text-2">By registering I agree to the </span> <span className="tc cursor-pointer">terms and conditions</span> </div> */}
                            <button className="btn btn-lg btn-block btn-primary border-radius mt-1 mb-3" onClick={() => this.sendOtp()}>
                                LOGIN
                            </button>
                        </div>
                    }
                </div>
            </div>
        )
    }


    render() {
        if (this.state.isLoginedIn) {
            let url = Path.COURSE

            if (this.courseId) {
                url = `${url}?course=${this.courseId}`

            }
            return (<Redirect to={url} />)
        }

        return (

            <div className="singup">
                <div className="signup-background">
                    <div className='header row no-gutters'>
                        <div className="col-6"> <a href="https://countingwell.com"> <img alt="" className="logo" src={HeaderLogo} /> </a></div>
                    </div>
                    {
                        !this.state.loading ?
                            <div>

                                <div className="parent-box mt-4">
                                    <div className="child-box px-2">
                                        <div className="cw-card cw-card-size">
                                            <img className="curve-top" alt="" src={CurveTop} />
                                            <img className="curve-bottom" alt="" src={CurveBottom} />

                                            <div className="py-5">
                                                <div className='cw-card-header pt-4 text-center'>
                                                    <h3><b>{this.state.type == "LOGIN" ? "Login" : this.state.isOtpVerified ? "ADDITIONAL DETAIL" : "REGISTER"}</b></h3>
                                                    {!this.state.isOtpVerified ? <span>{this.state.isOtpSent ? <span className="otp-sent"> OTP Sent to <strong className="otp-number">{this.state.mobileNumber}</strong></span> : ""}</span> : ""}
                                                </div>
                                                {
                                                    !this.state.isOtpVerified ?
                                                        <div className="row no-gutters pt-4 type-wrapper">
                                                            <div className="col my-auto text-center cursor-pointer" onClick={() => this.onSelectType("REGISTER")}>
                                                                <div className="d-flex justify-content-center">
                                                                    <img className="radio-button mr-1" alt="" src={this.state.type == "REGISTER" ? RadioActive : RadioInActive} />
                                                                    <span>For New Student</span>
                                                                </div>
                                                            </div>
                                                            <div className="col my-auto cursor-pointer" onClick={() => this.onSelectType("LOGIN")}>
                                                                <div className="d-flex">
                                                                    <img className="radio-button mr-1" alt="" src={this.state.type == "LOGIN" ? RadioActive : RadioInActive} />
                                                                    <span>For Existing Student</span>
                                                                </div>
                                                            </div>
                                                        </div> : ""}

                                                <div className="cw-card-body position-relative">
                                                    {this.state.type == "REGISTER" ? this.renderSignup() : this.renderSignIn()}
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            : ""
                    }
                </div>
            </div>
        );
    }

}

export default Singup;