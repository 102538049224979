import React, { Component } from 'react';
import './loading.scss';
import { ImageConstants } from '../../../constants/constants';
import { getImageSource } from '../../../utility/image-source'

class Loading extends Component {
    state = {}
    render() {
        return (
            <div className='loading-screen'>
                <div className='loading'>
                    <div className='loading-header'>
                        <img src={getImageSource(ImageConstants.LOADER_HEADER)}></img>
                    </div>
                    <div>
                        <img src={getImageSource(ImageConstants.LOADING_GIF)} className='loading-gif'></img>
                    </div>
                    <div className='loading-footer'>
                        <img src={getImageSource(ImageConstants.LOADER_FOOTER)}></img>
                    </div>
                </div>
            </div>);
    }
}

export default Loading;