import HttpClient from './http-client';
import URL from './url';
import axios from 'axios';
import qs from 'qs'
var request = require("request");

export default class PaymentAPI {

    static getSubscriptions(payload = {}) {

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.SUBSCRIPTIONS + "?" + params.join('&') : URL.SUBSCRIPTIONS;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static callAbstractApi(url) {
        return HttpClient.get(url, {});
    }

}

