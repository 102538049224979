import React from 'react';
import './header-component.scss';
import { Constants, ImageConstants } from '../../../constants/constants';
import { getImageSource } from '../../../utility/image-source'
import { Link, Redirect } from 'react-router-dom';
import Path from '../../routes/routes-path';
import Loading from '../../common/loading/loading';
import Service from '../../../apis/service-manager';
import Urls from '../../../apis/url';
import SessionCache from '../../routes/session-cache';


class Header extends React.Component {

    state = {
        isLogout: false,
        isLoading: false
    };

    constructor(props) {
        super(props);
    }

    logout = () => {

        this.setState({
            isLoading: true
        });

        Service.createData(Urls.LOGOUT, {}, (err, res) => {


            SessionCache.setUser({
                isAuthenticated: false
            });

            this.setState({
                isLoading: false,
            });

            setTimeout(() => {
                this.setState({
                    isLogout: true,
                });
            }, 200);

        })
    }

    render() {

        return (
            <div className={`cw-header row no-gutters ${this.props.transparent ? '' : 'bg-white'}`}>
                {this.state.isLoading && <Loading></Loading>}
                {this.state.isLogout && <Redirect to={Path.LOGIN} />}

                <div className="col-6">
                    <Link to={Path.DASHBOARD}>
                        <img className="logo" src={getImageSource(ImageConstants.CW_LOGO)} />
                    </Link>
                </div>
                <div className="col-6">
                    <div className="p-2 pt-3 float-right" onClick={this.logout}>
                        <span className="text">Logout</span>
                        <img className="text-icon pt-2 pb-2" src={getImageSource(ImageConstants.LOGOUT)}></img>
                    </div>
                    <Link to={Path.PROFILE}>
                        <div className="p-2 pt-3 float-right">
                            <span className="text">Hello, {SessionCache.getName()}</span>
                            <img className="text-icon" src={getImageSource(ImageConstants.USER_PLACEHOLDER)}></img>
                        </div>
                    </Link>
                </div>
            </div>
        );
    }
}

export default Header;