import HttpClient from './http-client';
import URL from './url';

export default class PaymentAPI {

    // static getHash(payload) {

    //     let params = [];
    //     if (payload) {
    //         Object.keys(payload).forEach(key => {
    //             params.push(`${key}=${payload[key]}`);
    //         });
    //     }
    //     let paramsList = params.length ? URL.PAYMENT_HASH + "?" + params.join('&') : URL.PAYMENT_HASH;
    //     return HttpClient.post(URL.BASE_URL + paramsList, payload);
    // }

    // static getSchloarshipHash(payload) {

    //     let params = [];
    //     if (payload) {
    //         Object.keys(payload).forEach(key => {
    //             params.push(`${key}=${payload[key]}`);
    //         });
    //     }
    //     let paramsList = params.length ? URL.SCHOLARSHIP_PAYMENT_HASH + "?" + params.join('&') : URL.SCHOLARSHIP_PAYMENT_HASH;
    //     return HttpClient.get(URL.BASE_URL + paramsList, payload);
    // }

    static getHash(payload) {
        return HttpClient.post(URL.BASE_URL + URL.PAYMENT_HASH, null, payload);
    }

    static getSchloarshipHash(payload) {
        return HttpClient.post(URL.BASE_URL + URL.SCHOLARSHIP_PAYMENT_HASH, null, payload);
    }


    static getCourseHash(payload) {
        return HttpClient.post(URL.BASE_URL + URL.COURSE_PAYMENT_HASH, null, payload);
    }


    static applyCoupon(payload) {
        return HttpClient.post(URL.BASE_URL + URL.APPLY_COUPON, null, payload);
    }

}

