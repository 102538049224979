import React from 'react';

import './login.scss';
import AuthenticateAPI from '../../../apis/authenticate'
import Path from '../../routes/routes-path';
import { Redirect } from 'react-router';
import EditIcon from '../../../assets/images/edit-icon.png'
import SessionCache from '../../routes/session-cache';
import appStoreImage from '../../../assets/images/app-store.png'
import playStoreImage from '../../../assets/images/play-store.png'
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import { validateMobileNumber } from '../../../utility/common-utilities'

const firebaseConfig = {
    apiKey: "AIzaSyBOQaznope_EJxUcCiXhdYUBpo9XbWEtgM",
    authDomain: "countingwell-22064.firebaseapp.com",
    projectId: "countingwell-22064",
    storageBucket: "countingwell-22064.appspot.com",
    messagingSenderId: "434404415565",
    appId: "1:434404415565:web:b521ffe4ee74a59479e404",
    measurementId: "G-9V6QN94W27"
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

class LogIn extends React.Component {
    planId = ""
    constructor(props) {
        super(props);
        let urlParams = new URLSearchParams(window.location.search);
        this.schoolCoupon = urlParams.get('scpn')
        this.schoolId = urlParams.get('sid')
    }

    state = {
        mobileNumber: "",
        otp: '',
        dot: "",
        isInvalidMobile: false,
        isSubmit: false,
        isInvalidOtp: false,
        isOtpSent: false,
        selectedCountry: { dialCode: "+91", length: 10 },
        countryCodeDropDown: [],
    }

    resendIntervel
    coutryCodeHash = {}

    componentDidMount() {
        this.getCountryCode()
    }

    onChangeHandler = (event, key) => {
        if (key === "mobileNumber") {

            if (!this.allowOnlyNumbers(event.target.value)) return

            this.setState({
                [key]: event.target.value,
                isInvalidMobile: false,
                isLoginEnable: this.validateMobile(event.target.value)
            });
        } else {
            this.setState({ [key]: event.target.value }, () => {
                this.setState({
                    isLoginEnable: this.validateOTP()
                })
            });
        }
    }

    allowOnlyNumbers(value) {
        const re = /^[0-9\b]+$/;

        if (value === '' || re.test(value)) {
            return true
        }
        return false
    }

    validateMobile(value) {

        if (value) {
            let countryCodeDetail = this.coutryCodeHash[this.state?.selectedCountry?.dialCode]
            let validate = validateMobileNumber(value, countryCodeDetail)
            return validate
        }
        return false
    }

    validateOTP() {

        if (this.state.otpBox1 && this.state.otpBox2 && this.state.otpBox3 && this.state.otpBox4) {
            return true
        }
        return false
    }

    getCountryCode(payload = {}) {
        AuthenticateAPI.getCountyList(payload).then((response) => {
            let data = response.response
            if (data.length) {
                this.setState({ countryList: data })
                this.buildCountryCodeDropDown(data)
            } else {
                this.setState({ countryList: [], countryCodeDropDown: [] })
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    buildCountryCodeDropDown(list = []) {
        let countryCodeDropDown = []
        list.map(item => {
            this.coutryCodeHash[item.dialCode] = item
            countryCodeDropDown.push({ text: item.dialCode, addOn: item.name, value: item.dialCode })
        })
        this.setState({ countryCodeDropDown })
    }

    onSubmit() {
        if (this.state.isOtpSent) {
            this.verifyOtp()
        } else {
            this.sendOtp()
        }
    }

    sendOtp() {

        const mobileNumber = this.state.mobileNumber
        const countryCode = this.state.selectedCountry.dialCode
        let mobileNumberValidation = this.validateMobile(this.state.mobileNumber)
        if (!mobileNumberValidation) {
            this.setState({ isInvalidMobile: true, showDownload: false });
            return;
        } else {
            this.setState({ isInvalidMobile: false });
        }

        this.setState({ isSubmit: true });

        const loadingIntervel = setInterval(() => {
            if (this.state.dot.length === 3) {
                this.setState({ dot: `.` })
            } else {

                this.setState({ dot: `${this.state.dot}.` })
            }
        }, 500)

        if (mobileNumber && countryCode) {

            let payload = { mobileNumber, countryCode };

            AuthenticateAPI.sendOtpForSchoolCoupon(payload).then((userDetails) => {

                this.setState({ isOtpSent: true, isLoginEnable: false, isSubmit: false, dot: "", otpBox1: "", otpBox2: "", otpBox3: "", otpBox4: "" });
                clearInterval(loadingIntervel)

            }).catch((err) => {
                clearInterval(loadingIntervel)
                this.setState({ isSubmit: false, dot: "", isInvalidMobile: true, showDownload: true })
            })
        }
    }

    verifyOtp() {
        const mobileNumber = this.state.mobileNumber
        const countryCode = this.state.selectedCountry.dialCode
        const otp = `${this.state.otpBox1 || ''}${this.state.otpBox2 || ''}${this.state.otpBox3 || ''}${this.state.otpBox4 || ''}`
        if (!this.state.mobileNumber || !otp || otp.length !== 4) {
            this.setState({ isInvalidOtp: true })
            return;
        }

        this.setState({ isSubmit: true });

        const loadingIntervel = setInterval(() => {
            if (this.state.dot.length === 3) {
                this.setState({ dot: `.` })
            } else {

                this.setState({ dot: `${this.state.dot}.` })
            }
        }, 500)

        if (mobileNumber && otp) {

            let payload = { mobileNumber, otp, countryCode };

            AuthenticateAPI.verifyUserOtp(payload).then((userDetails) => {
                clearInterval(loadingIntervel)
                SessionCache.user = userDetails.data
                this.setState({ isLoginedIn: true })
            }).catch((err) => {
                clearInterval(loadingIntervel)
                this.setState({ isLoginFailed: true, dot: "" })
                alert("Invalid credentials");
            })
        }

    }

    resendOtp() {
        this.sendOtp()
        this.setState({ time: 60 }, () => {
            this.resendIntervel = setInterval(() => {
                this.reduceTime(this.state.time - 1)
            }, 1000)
        })
    }


    onFocusInput = () => {
        if (this.state.isLoginFailed) {
            this.setState({ isLoginFailed: false })
        }
    }

    reduceTime(time) {
        this.setState({ time })
        if (!time) return clearInterval(this.resendIntervel)
    }

    onKeyUpEvent(index, event) {
        const eventOtp = event.which || event.keyCode;
        if (this.getOtpBoxElement(index).value.length === 1) {
            if (index !== 4) {
                this.getOtpBoxElement(index + 1).focus()
                this.getOtpBoxElement(index + 1).select();
            } else {
                this.getOtpBoxElement(index).blur();
            }
        }
        if (eventOtp === 8 && index !== 1) {
            this.getOtpBoxElement(index - 1).focus()
            this.getOtpBoxElement(index - 1).select();
        }
    }

    onFocusEvent(index) {
        if (this.state.isLoginFailed) {
            this.setState({ isLoginFailed: false })
        }
        for (let item = 1; item < index; item++) {
            const currentElement = this.getOtpBoxElement(item);
            if (!currentElement.value) {
                currentElement.focus();
                currentElement.select()
                break;
            }
        }
    }

    getOtpBoxElement(index) {
        return document.getElementById('otpBox' + index);
    }

    onMobileNumberEdit() {
        this.setState({ mobileNumber: "", isOtpSent: false, time: 0, isLoginEnable: false, isInvalidOtp: false })
        clearInterval(this.resendIntervel)
    }

    renderOtpInput() {
        return (
            <div className="otp-boxes">
                <input id="otpBox1" className={`${this.state.isLoginFailed || this.state.isInvalidOtp ? 'invalid' : ''}`} type="text" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(1, event)} onFocus={() => this.onFocusEvent(1)} onChange={(e) => this.onChangeHandler(e, 'otpBox1')} value={this.state.otpBox1} />
                <input id="otpBox2" className={`${this.state.isLoginFailed || this.state.isInvalidOtp ? 'invalid' : ''}`} type="text" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(2, event)} onFocus={() => this.onFocusEvent(2)} onChange={(e) => this.onChangeHandler(e, 'otpBox2')} value={this.state.otpBox2} />
                <input id="otpBox3" className={`${this.state.isLoginFailed || this.state.isInvalidOtp ? 'invalid' : ''}`} type="text" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(3, event)} onFocus={() => this.onFocusEvent(3)} onChange={(e) => this.onChangeHandler(e, 'otpBox3')} value={this.state.otpBox3} />
                <input id="otpBox4" className={`${this.state.isLoginFailed || this.state.isInvalidOtp ? 'invalid' : ''}`} type="text" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(4, event)} onFocus={() => this.onFocusEvent(4)} onChange={(e) => this.onChangeHandler(e, 'otpBox4')} value={this.state.otpBox4} />
            </div>)
    }

    openCountryCode() {
        if (this.state.isOtpSent) return
        this.getCountryCode()
        this.setState({ showDropdown: !this.state.showDropdown })
    }

    onSelectCode(item) {
        this.setState({ selectedCountry: this.coutryCodeHash[item.value], showDropdown: false }, () => {
            this.setState({ isLoginEnable: this.validateMobile(this.state.mobileNumber) })
        })

    }

    onSearch(event) {
        let search = event.target.value
        this.getCountryCode({ name: `/${search}`, })
    }

    countryCodeDropDown(dropDownList = []) {
        return (
            <div className="country-code-drop-down" id="st-3">
                <div className="p-2">
                    <div className="search">
                        <input className="form-control" placeholder={`Search`} value={this.state.search} onChange={(event) => { this.onSearch(event) }} />
                    </div>
                    <div>
                        {dropDownList.length ?
                            dropDownList.map(dropdown => (
                                <div className="dropdown-list cursor-pointer px-3 py-1" onClick={() => { this.onSelectCode(dropdown) }}>
                                    <div className="row country-name cursor-pointer">
                                        <div className="col-8"> {dropdown.addOn}</div>
                                        <div className="col text-right">  {dropdown.text}</div>
                                    </div>
                                </div>

                            ))
                            : <div className="text-center py-4 no-data">
                                <label> No Records found</label>
                            </div>
                        }</div>
                </div>
            </div>
        )
    }

    renderShowDownload() {
        return (
            <div className="show-app-download p-4">

                <ul className="progress-tracker progress-tracker--vertical">
                    <li className="progress-step is-complete">
                        <div className="progress-marker color1"> 1</div>
                        <div className="progress-text pb-20">
                            <span className="store-buttons store-black d-flex flex-wrap">
                                <span className="progress-title font-weight-normal font-18 mb-0">Download the App</span>
                                <a className="px-2" href="https://apps.apple.com/in/app/countingwell/id1508540117" target="_blank"><img className="app-link" src={appStoreImage} alt="" /></a>
                                <a className="pr-2" href="https://play.google.com/store/apps/details?id=com.cwapp" target="_blank"><img className="app-link" src={playStoreImage} alt="" /></a>
                            </span>
                        </div>
                    </li>
                    <li className="progress-step is-complete">
                        <div className="progress-marker color2"> 2 </div>
                        <div className="progress-text">
                            <h4 className="progress-title font-weight-normal font-18 mb-0">Complete Registration on App</h4>
                        </div>
                    </li>
                    <li className="progress-step is-active" aria-current="step">
                        <div className="progress-marker color3"> 3 </div>
                        <div className="progress-text">
                            <h4 className="progress-title font-weight-normal font-18 mb-0">Comeback here, Login & Upgrade </h4>
                        </div>
                    </li>
                </ul>
            </div>
        )
    }

    loginAnotherNumber = () => {
        this.setState({ showDownload: false, mobileNumber: "", isSubmit: false, isInvalidMobile: false })
    }

    render() {

        if (this.state.isLoginedIn) {
            let url = Path.USER_LIST
            if (this.schoolCoupon) {
                url = `${url}?scpn=${this.schoolCoupon}&sid=${this.schoolId}`
            }

            return (<Redirect to={url} />)
        }

        return (
            <div className="login">

                <section className="hero-section full-screen gray-light-bg">
                    <div className="container-fluid">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-12 col-md-6 col-lg-5 col-xl-5 px-lg-6 my-5">
                                <div className="login-signup-wrap px-4 px-lg-5 my-5">

                                    <h2 className="text-center mb-3">
                                        Existing User
                                    </h2>

                                    <p className="text-muted text-center mb-5">
                                        Log In with your registered mobile number
                                    </p>

                                    <div className="login-signup-form">
                                        <div className="form-group">

                                            <label className="pb-1">
                                                Mobile Number
                                            </label>

                                            <div className={`cw-card mobile-number ${this.state.isInvalidMobile ||
                                                (this.state.isSubmit && !this.state.mobileNumber) ? 'invalid' : ''}`}>

                                                <div className="">
                                                    <span className={`px-3 country-code ${this.state.isOtpSent ? "cursor: default" : "cursor-pointer"}`} onClick={() => this.openCountryCode()}>
                                                        <strong> {this.state.selectedCountry.dialCode} </strong>
                                                    </span>
                                                    <input className="cw-input number"
                                                        placeholder='Enter mobile number'
                                                        onChange={(e) => this.onChangeHandler(e, 'mobileNumber')}
                                                        onBlur={(event) => this.validateMobile(event.target.value)}
                                                        onFocus={this.onFocusInput}
                                                        value={this.state.mobileNumber ? this.state.mobileNumber : ""}
                                                        disabled={this.state.isOtpSent} />
                                                    {
                                                        this.state.isOtpSent ?
                                                            <span className="float-right mr-4 mt-2">
                                                                <img className="cursor-pointer" onClick={() => this.onMobileNumberEdit()} src={EditIcon} />
                                                            </span> : ""
                                                    }

                                                </div>
                                            </div>

                                            {(this.state.isSubmit && !this.state.mobileNumber) || (this.state.isInvalidMobile && !this.state.showDownload) ? <span className='invalid-text'>Please enter valid mobile number</span> : ''}
                                            {
                                                this.state.showDownload ?
                                                    <div>
                                                        <span className='invalid-text'>This Mobile number is not registered with Countingwell</span>
                                                        <span className='resent-otp otp-color pt-2' onClick={this.loginAnotherNumber}>LOGIN WITH ANOTHER NUMBER</span>
                                                    </div> : ''
                                            }
                                        </div>

                                        <div className="abs-div" onMouseLeave={() => this.openCountryCode()}>
                                            {this.state.showDropdown ? this.countryCodeDropDown(this.state.countryCodeDropDown) : ""}
                                        </div>

                                        <div>
                                            {this.state.isOtpSent ?
                                                <div>
                                                    <div className='row text-left pt-2'>
                                                        <div className='col'>
                                                            <div className="form-group">
                                                                <label className="pb-1">
                                                                    OTP
                                                                </label>
                                                                {/* <strong className="input-label">OTP <span className='star'>*</span></strong> */}
                                                                {this.renderOtpInput()}
                                                                {this.state.isLoginFailed || this.state.isInvalidOtp ? <span className='invalid-text'>Enter valid otp</span> : ''}
                                                                {/* {this.state.isSubmit && !this.state.otp ? <span className='invalid-text'>Please enter otp</span> : ''} */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="pt-2">{
                                                        this.state.time ?
                                                            <span className="resent-otp">Resend OTP in {this.state.time}</span>
                                                            : <span className="resent-otp otp-color" onClick={() => this.resendOtp()} >Resend OTP </span>
                                                    }
                                                    </div>
                                                </div>
                                                : ""}

                                        </div>

                                        <button className="btn btn-lg btn-block solid-btn-login border-radius mt-4 mb-3" onClick={() => this.onSubmit()}>
                                            Log In
                                        </button>
                                    </div>
                                </div>
                                {
                                    this.state.showDownload ? this.renderShowDownload() : ""
                                }
                            </div>
                            <div className="col-12 col-md-6 col-lg-7 col-xl-7 p-0">

                                <div className="bg-cover vh-100 background-image">
                                    <div className="login-signup-content">
                                        <div className="position-relative col-md-12 col-lg-7">
                                            <h2 className="text-white text-center">New Users</h2>

                                            <div className="about-area-content box mt-30 wow fadeInLeft" data-wow-delay="0.4s">
                                                <h5 className="font-weight-normal">Follow the steps to join Countingwell</h5>
                                                <ul className="progress-tracker progress-tracker--vertical">
                                                    <li className="progress-step is-complete">
                                                        <div className="progress-marker color1"></div>
                                                        <div className="progress-text pb-20">
                                                            <h4 className="progress-title font-weight-normal font-18 mb-0">Download the App</h4>
                                                            <div className="store-buttons store-black d-flex flex-wrap">
                                                                <a className="mt-3 w-50 pr-2" href="https://apps.apple.com/in/app/countingwell/id1508540117" target="_blank"><img src={appStoreImage} width="100%" alt="" /></a>
                                                                <a className="mt-3 w-50 pr-2" href="https://play.google.com/store/apps/details?id=com.cwapp" target="_blank"><img src={playStoreImage} width="100%" alt="" /></a>
                                                            </div>
                                                            <p><small className="font-13 d-none d-lg-block d-md-block"><i>If you are viewing this on your desktop, please go to App Store or Play Store to download</i></small></p>
                                                        </div>
                                                    </li>
                                                    <li className="progress-step is-complete">
                                                        <div className="progress-marker color2"></div>
                                                        <div className="progress-text">
                                                            <h4 className="progress-title font-weight-normal font-18 mb-0">Register on the App</h4>
                                                        </div>
                                                    </li>
                                                    <li className="progress-step is-active" aria-current="step">
                                                        <div className="progress-marker color3"></div>
                                                        <div className="progress-text">
                                                            <h4 className="progress-title font-weight-normal font-18 mb-0">Upgrade to premium by entering your registered mobile number</h4>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>


                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        );
    }
}

export default LogIn;