import React from 'react';
import "./school-coupon.scss";
import CouponApi from '../../../apis/coupon'
import Gift from '../../../assets/images/giftbox1.png'
import OfferBackground from '../../../assets/images/scholarship-offer.png'
import Expired from '../../../assets/images/expired.png'
import HeaderLogo from '../../../assets/images/logo.png'
import SubscriptionAmount from './subscription-amount/subscription-amount'
import { Constants } from '../../../constants/constants'
import SubscriptionPlanAPI from '../../../apis/subscription-plan'
import SuccessImage from '../../../assets/images/cw-subscribe.png'
import Path from '../../routes/routes-path'

class UsersListSubscrption extends React.Component {
    planId = ""
    state = {
        name: '',
        offer: "",
        subscriptionList: [],
        users: [],
        selectedUsers: [],
        subscription: {},
        isInfluenceCoupon: false,
        couponAmount: 0,
        coupon: {},
        subscription: {},
        expired: false,
        loading: true,
        myCountryPrice: "",
        indiaAmount: ""
    }

    constructor(props) {
        super(props)

        let urlParams = new URLSearchParams(window.location.search);
        this.state.id = urlParams.get('id')
        this.state.schooId = urlParams.get('sid')
    }

    componentDidMount() {

        this.getCoupon()
    }

    getCoupon() {

        CouponApi.getSchoolCoupons({ coupon: this.state.id, schoolId: this.state.schooId }).then((response) => {

            let subscriptions = response.subscriptions || []
            let discount = response.discount
            let discountAmount = 0
            let totalAmount = subscriptions[0].discountedPrice
            let offer = ""
            if (discount.type == Constants.DISCOUNT_TYPE.AMOUNT) {
                discountAmount = discount.value
                offer = `₹ ${discount.value}`
            }

            if (discount.type == Constants.DISCOUNT_TYPE.PERCENTAGE) {
                discountAmount = (totalAmount * discount.value) / 100
                offer = `${discount.value} %`
            }
            let curreny = subscriptions[0]?.currency?.code
            this.getCurreny(curreny)

            if (subscriptions) {
                this.setState({ discount, offer, subscriptions, subscription: subscriptions[0], discountAmount, loading: false })
            } else {
                this.setState({ loading: false })
            }

            if (response.expireTime) {
                this.setExpireTimeInterval(response.expireTime / 1000)
            } else if (response.expireDays) {
                this.setState({ alertText: `HURRY! Discount valid for next ${response.expireDays} days` })
            } else if (response.expireDate) {

                let dateFormat = new Date(response.expireDate).toDateString();

                let date = dateFormat.split(" ")[2];

                let month = dateFormat.split(" ")[1];

                let year = dateFormat.split(" ")[3];

                let finalDate = date + "th " + month + " " + year;
                this.setState({ alertText: `HURRY! Discount valid till ${finalDate}` })
            }

        }).catch((err) => {
            if (err?.response?.data?.code == 2003) {
                this.setState({ expired: true, loading: false })
            }

            if (err?.response?.data?.code == 2005) {
                let a = document.createElement('a')
                a.href = err?.response?.data?.link
                a.click()
            }
        })
    }


    getCurreny(baseCurrency = "USD", amount) {

        SubscriptionPlanAPI.callAbstractApi(Constants.ABSTRACT_LOCATION_API).then((response) => {
            let targetCurreny = response?.currency?.currency_code || "INR"
            if (baseCurrency == targetCurreny) return
            let url = `${Constants.ABSTRACT_EXCHANGE_RATE_API}&base=${baseCurrency}&target${targetCurreny}`
            SubscriptionPlanAPI.callAbstractApi(url).then((response) => {
                let targetAmount = response.exchange_rates ? response.exchange_rates[targetCurreny] : ""
                let indiaAmount = response.exchange_rates ? response.exchange_rates["INR"] : ""
                amount = amount * targetAmount
                this.setState({ myCountryPrice: targetAmount, myCurrency: targetCurreny, indiaAmount })
            }).catch((err) => {
                this.setState({ myCountryPrice: "" })
            })
        }).catch((err) => {
        })
    }


    setExpireTimeInterval(expiryTime) {

        this.timerInterval = setInterval(() => {
            expiryTime -= 1
            let hours = expiryTime / (60 * 60);
            let absoluteHours = Math.floor(hours);
            let h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;

            let minutes = (hours - absoluteHours) * 60;
            let absoluteMinutes = Math.floor(minutes);
            let m = absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes;

            let seconds = (minutes - absoluteMinutes) * 60;
            let absoluteSeconds = Math.floor(seconds);
            let s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds;
            let expireTimeString = h + ":" + m + ":" + s
            let alertText = `HURRY! Discount valid for ${expireTimeString}`
            this.setState({ expiryTime, alertText })
            if (expiryTime <= 0) {
                this.setState({ expired: true })
                clearInterval(this.timerInterval)
            }
        }, 1000)
    }

    onSubscribe = () => {
        let path = `${Path.SCHOOL_COUPON_LOGIN}?scpn=${this.state.id}&sid=${this.state.schooId}`
        this.props.history.push(path)
    }

    hidePlans = () => {
        this.setState({ showPlans: false })
    }


    getFormattedAmount(amount) {
        return amount ? amount.toLocaleString('en') : amount
    }

    onSelectSubscription(subscription) {
        let discount = this.state.discount
        let discountAmount = 0
        let totalAmount = subscription.discountedPrice
        if (discount.type == Constants.DISCOUNT_TYPE.AMOUNT) {
            discountAmount = discount.value
        }

        if (discount.type == Constants.DISCOUNT_TYPE.PERCENTAGE) {
            discountAmount = (totalAmount * discount.value) / 100
        }

        this.getCurreny(subscription?.curreny?.code)
        this.setState({ subscription, discountAmount, showPlans: false })
    }

    renderOffer() {
        return (
            <div className="parent-box pt-5">
                <div className="child-box">
                    <div className="cw-card cw-card-size">
                        <div className="offer-card p-2">
                            <div className="background">
                                <img src={OfferBackground} />
                            </div>
                            <div className="row">
                                <div className="col-2 text-right my-auto p-0">
                                    <img className="giftbox" src={Gift} />
                                </div>

                                <div className="col py-2" >
                                    <div className="offer-title ">Congratulations!</div>
                                    <div className="offer-content pt-1">{this.state.offer} Discount applied on your Annual Plan</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderExpiredCard() {
        return (
            <div className="expired-card pt-5 px-4 pb-2">

                <div className="expired-image text-center">
                    <img src={Expired} />
                </div>

                <div className="opps pt-4" >
                    Opps!
                </div>

                <div className="text-1">
                    {`Your Scholarship Discount code is expired & no more valid now.`}
                </div>

                <div className="footer text-center">
                    <div className="upgrade text-center">Upgrade your account</div>
                    <div className="text-center pt-4">
                        <a href="https://countingwell.com/premium_subscription.html" className={`cw-button btn btn-md`} onClick={() => this.onRedirctTo()}> CONTINUE</a>
                    </div>
                </div>
            </div>
        )
    }


    render() {

        return (

            <div className="school-coupon">
                <div className="user-subscription-background">
                    <div className='header row no-gutters'>
                        <div className="col-6"> <img className="logo" src={HeaderLogo} /></div>
                    </div>
                    {
                        !this.state.loading ?
                            <div>
                                {this.state.showForm ? this.onShowForm(this.state.payload) : ""}

                                {
                                    !this.state.expired ? this.renderOffer() : ""
                                }
                                <div className="parent-box pt-3">
                                    <div className="child-box">
                                        <div className="cw-card cw-card-size">
                                            {
                                                !this.state.expired ?
                                                    <div>
                                                        <div className='cw-card-header pt-4 text-center'>

                                                            <div className="icon-size pt-2"><img src={SuccessImage} /></div>
                                                            <h5><b>Hey!</b></h5>
                                                            <div className="text-1 pt-2">Here is your {this.state.subscription?.displayPlanTitle} plan with discounted price</div>
                                                            {this.state.alertText ? <div className="hurry"> {this.state.alertText} </div> : ""}
                                                        </div>

                                                        <div className="cw-card-body position-relative">
                                                            <div>
                                                                <div className="">
                                                                    <SubscriptionAmount
                                                                        name={this.state.subscription?.planName}
                                                                        basePrice={this.state.subscription?.basePrice}
                                                                        discountedPrice={this.state.subscription?.discountedPrice}
                                                                        discount={this.state.offer}
                                                                        subTitle={`Valid for ${this.state.subscription?.numberOfDays} days`}
                                                                        couponAmount={this.state.discountAmount}
                                                                        currency={this.state.subscription?.currency?.symbol}
                                                                        myCountryPrice={this.state.myCountryPrice}
                                                                        myCurrency={this.state.myCurrency}
                                                                        indiaAmount={this.state.indiaAmount
                                                                        }
                                                                    />
                                                                </div>

                                                                {
                                                                    this.state.errorMessage ? <div className="p-2 text-center"> <span className="error-message text-danger">{this.state.errorMessage}</span> </div> : ""
                                                                }

                                                                {
                                                                    this.state.subscription && this.state.subscription._id ?
                                                                        <div className="pt-2">
                                                                            {
                                                                                this.state.users ?
                                                                                    <div className="text-center">
                                                                                        <div className="gst-text p-2">Price mentioned is inclusive of GST</div>
                                                                                        <span className={`cw-button btn btn-md `} onClick={() => this.onSubscribe()}> CONTINUE</span>
                                                                                    </div>
                                                                                    : ""
                                                                            }
                                                                        </div>
                                                                        : ""
                                                                }
                                                            </div>
                                                        </div>

                                                    </div> : this.renderExpiredCard()
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>
                            : ""
                    }
                </div>
            </div>
        );
    }

}

export default UsersListSubscrption;