import React from 'react';
import "./subscription-amount.scss";
import Ellipse from '../../../assets/images/ellipse.png'
import Divider from '../../../assets/images/divider.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faCircle } from '@fortawesome/free-solid-svg-icons'
class Coupon extends React.Component {

    state = {
        isInvalidCoupon: false,
        courses: this.props.courses || []
    }

    componentDidMount() {
    }


    datePipe(givenDate, isTime = false) {
        givenDate = new Date(givenDate).toString()
        let [day, month, date, year, time] = givenDate.split(' ')
        return `${date} ${month}, ${year} ${isTime ? time.substr(0, 5) : ""}`

    }

    onChangeHandler(event) {
        this.setState({ isInvalidCoupon: false })
    }

    getFormattedAmount(amount) {
        return amount ? amount.toLocaleString('en') : amount
    }

    onClickSelectCourse(item, index) {
        let courses = this.state.courses
        item.checked = !item.checked
        courses[index] = item
        this.setState({ courses }, () => {
            let selectedCourses = courses.filter(item => item.checked)
            this.props.onSelectCourse(selectedCourses)
        })
    }

    getAppoxText() {
        if (this.props.currency == "₹") return ""
        let amount = this.getFinalPayableAmount()
        const countryPrice = Number(this.props.myCountryPrice * amount).toFixed(2)
        const indiaPrice = Number(this.props.indiaAmount * amount).toFixed(2)
        return `( Approximately ${countryPrice} ${this.props.myCurrency} )`
    }

    getFinalPayableAmount() {
        let courseAmount = 0

        for (let i = 0; i < this.state.courses?.length; i++) {
            if (this.state.courses[i].checked) courseAmount += this.state.courses[i].bundlePrice
        }

        return (this.props.discountedPrice - this.props.couponAmount) + courseAmount
    }

    renderAppliedCoupon() {
        return (
            <div className="py-2">
                {
                    this.props.couponAmount > 0 ?
                        <div>
                            <div className="applied-coupon">
                                <div className="d-inline-block">
                                    <div className="flex">
                                        <span className="discount-text pr-1">Discount</span>
                                    </div>
                                </div>

                                <div className="d-inline-block pl-4">
                                    <span className="coupon-amt"> - {this.props.currency} {this.getFormattedAmount(this.props.couponAmount)}</span>
                                </div>
                            </div>

                            <div>
                                <img className="divider-img" src={Divider} alt="" />
                            </div>

                            {/* Commented to not show the Courses for RR 2024 Event Discount */}

                            {/* {
                                this.state.courses?.length ?
                                    <div className="course-container pb-4 text-left">
                                        <div className="row m-0">
                                            <div className="col text-center course-header-name">Add other courses</div>
                                        </div>
                                        <div className="pt-2">
                                            {
                                                this.state.courses?.map((item, index) => (
                                                    <div className="row m-0 pt-3 cursor-pointer" onClick={() => this.onClickSelectCourse(item, index)}>
                                                        <div className="col pr-0">
                                                            <div className=" d-flex">
                                                                <div className="pr-1">
                                                                    {
                                                                        item.checked ?
                                                                            <FontAwesomeIcon color="green" icon={faCheckCircle} />
                                                                            :
                                                                            <img alt="" className="ellipse" src={Ellipse} />

                                                                    }

                                                                </div>
                                                                <div>{item.planName}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-4 pl-0">
                                                            <span className="amt-strike">
                                                                <span className="amount">{item.currency?.symbol} {this.getFormattedAmount(item.basePrice)}</span>
                                                            </span>

                                                            <span className="payable-amount float-right">{item.currency?.symbol} {this.getFormattedAmount(item.bundlePrice)}</span>
                                                        </div>
                                                    </div>
                                                ))
                                            }

                                        </div>
                                    </div>
                                    : ""
                            } */}


                            <div className="pb-2">
                                <div className="font-14"><b>You Pay</b></div>
                                <span className="payable-amount">
                                    {this.props.currency} {this.getFormattedAmount(this.getFinalPayableAmount())}
                                </span>
                            </div>
                            <div className="text-center">
                                <div className="my-country-price text-center pt-2">
                                    {
                                        this.props.myCountryPrice ? this.getAppoxText() : ""
                                    }
                                </div>
                            </div>
                        </div>
                        : ""}
            </div>
        )
    }

    render() {

        return (
            <div className="subscription-amount text-center">
                <div className=""><span className="view-plan cursor-pointer" onClick={this.props.onClickViewPlan}> {`VIEW PLANS > `}</span></div>
                <div className="header-name pt-4">{this.props.name}</div>
                <div className="pt-2 valid-till"> <span>{this.props.subTitle}</span></div>
                <div className="d-inline-block pt-3">
                    <span className="amt-strike">
                        <span className="amount">{this.props.currency} {this.getFormattedAmount(this.props.discountedPrice)}</span>
                    </span>
                </div>
                {
                    this.renderAppliedCoupon()
                }
            </div>
        );
    }

}

export default Coupon;