import React from 'react';
import "./course-subscribe.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faSquare } from '@fortawesome/free-solid-svg-icons'
import PaymentAPI from '../../apis/payment'
import UsersAPI from '../../apis/users'
import Gift from '../../assets/images/giftbox1.png'
import OfferBackground from '../../assets/images/scholarship-offer.png'
import Expired from '../../assets/images/expired.png'
import CloseIcon from '../../assets/images/clear.png'
import AppLink from '../app-link/app-link'
import HeaderLogo from '../../assets/images/logo.png'
import SubscriptionAmount from './subscription-amount/subscription-amount'
import { Constants } from '../../constants/constants'
import SubscriptionPlanAPI from '../../apis/subscription-plan'
import Ellipse from '../../assets/images/ellipse.png'

class UsersListSubscrption extends React.Component {
    planId = ""
    state = {
        name: '',
        offerText: "",
        subscriptionList: [],
        users: [],
        selectedUsers: [],
        subscription: {},
        isInfluenceCoupon: false,
        couponAmount: 0,
        coupon: {},
        expired: false,
        loading: true,
        myCountryPrice: "",
        indiaAmount: "",
        alertText: "",
        discountedText: ""
    }

    constructor(props) {
        super(props)

        let urlParams = new URLSearchParams(window.location.search);
        this.state.courseId = urlParams.get('course')
    }

    componentDidMount() {

        this.getCourses()
        this.getUsers()
    }

    getCourses() {
        UsersAPI.getCourses({ _id: this.state.courseId }).then((response) => {
            let course = response.course
            let discountAmount = course.discountedPrice

            let curreny = course?.currency?.code
            this.getCurreny(curreny)

            this.setState({
                subscription: course,
                offerText: course.offerText,
                alertText: course.alertText,
                discountAmount, loading: false,
                discountedText: course.discountedText
            })


        }).catch((err) => {
            if (err?.response?.data?.code == 2003) {
                this.setState({ expired: true, loading: false })
            }

            if (err?.response?.data?.code == 2005) {
                let a = document.createElement('a')
                a.href = err?.response?.data?.link
                a.click()
            }

            this.setState({ loading: false })
        })
    }

    getUsers() {
        UsersAPI.getCourseUsers({}).then((response) => {
            let data = response.data
            if (data.length) {
                this.setState({ users: data })
            } else {
                this.setState({ users: [] })
            }
        }).catch((err) => {
            alert("Something went wrong");
        })
    }


    getCurreny(baseCurrency = "USD", amount) {

        SubscriptionPlanAPI.callAbstractApi(Constants.ABSTRACT_LOCATION_API).then((response) => {
            let targetCurreny = response?.currency?.currency_code || "INR"
            if (baseCurrency == targetCurreny) return
            let url = `${Constants.ABSTRACT_EXCHANGE_RATE_API}&base=${baseCurrency}&target${targetCurreny}`
            SubscriptionPlanAPI.callAbstractApi(url).then((response) => {
                let targetAmount = response.exchange_rates ? response.exchange_rates[targetCurreny] : ""
                let indiaAmount = response.exchange_rates ? response.exchange_rates["INR"] : ""
                amount = amount * targetAmount
                this.setState({ myCountryPrice: targetAmount, myCurrency: targetCurreny, indiaAmount })
            }).catch((err) => {
                this.setState({ myCountryPrice: "" })
            })
        }).catch((err) => {
        })
    }


    setExpireTimeInterval(expiryTime) {

        this.timerInterval = setInterval(() => {
            expiryTime -= 1
            let hours = expiryTime / (60 * 60);
            let absoluteHours = Math.floor(hours);
            let h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;

            let minutes = (hours - absoluteHours) * 60;
            let absoluteMinutes = Math.floor(minutes);
            let m = absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes;

            let seconds = (minutes - absoluteMinutes) * 60;
            let absoluteSeconds = Math.floor(seconds);
            let s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds;
            let expireTimeString = h + ":" + m + ":" + s
            let alertText = `HURRY! Discount valid for ${expireTimeString}`
            this.setState({ expiryTime, alertText })
            if (expiryTime <= 0) {
                this.setState({ expired: true })
                clearInterval(this.timerInterval)
            }
        }, 1000)
    }

    onSubscribe = () => {
        if (this.state.isCreatingHash) return
        if (!this.state.subscription || !this.state.subscription._id) return
        if (!this.state.selectedUser) return

        let payload = {
            subscriptionId: this.state.subscription._id,
            courseId: this.state.subscription?.courseId,
            userId: this.state.selectedUser?._id
        }

        if (this.state.couponId && this.state.couponAmount > 0) {
            payload.couponId = this.state.couponId
            payload.coupon = this.state.couponCode
            payload.activityId = this.activityId
            payload.couponType = Constants.COUPON_TYPE.PUBLIC
        }

        if (this.state.selectedCourse?.length) {
            let addOn = []
            this.state.selectedCourse.map(element => {
                let obj = {
                    subscriptionId: element._id,
                }

                if (element.courseId) {
                    obj.courseId = element.courseId
                }

                addOn.push(obj)
            })
            payload.addOn = addOn
        }

        this.setState({ isCreatingHash: true })

        PaymentAPI.getCourseHash(payload).then((response) => {
            let payload = response.data ? response.data.hash : ""
            if (payload) {
                this.setState({ showForm: true, payload, isCreatingHash: false, errorMessage: "" })
            }
        }).catch((err) => {
            if (err.response) {
                if (err.response.status === 900) {
                    this.setState({ errorMessage: "Invalid Coupon" })
                }
            }
            this.setState({ isCreatingHash: false, })
            alert("Something went wrong");
        })
    }

    submitForm() {
        setTimeout(() => {
            document.payuform.submit();
        }, 1000)
    }

    hidePlans = () => {
        this.setState({ showPlans: false })
    }

    onClickUser(user) {
        this.setState({ selectedUser: user })
    }

    getFormattedAmount(amount) {
        return amount ? amount.toLocaleString('en') : amount
    }

    onSelectSubscription(subscription) {
        let discount = this.state.discount
        let discountAmount = 0
        let totalAmount = subscription.discountedPrice
        if (discount.type == Constants.DISCOUNT_TYPE.AMOUNT) {
            discountAmount = discount.value
        }

        if (discount.type == Constants.DISCOUNT_TYPE.PERCENTAGE) {
            discountAmount = (totalAmount * discount.value) / 100
        }

        this.getCurreny(subscription?.curreny?.code)
        this.setState({ subscription, discountAmount, showPlans: false })
    }

    onShowForm = (payload) => {
        return (
            <div style={{ "display": "none" }}>
                <form action={payload.actionUrl} name="payuform" id="payuform" method="POST" >
                    <input type="hidden" name="key" value={payload.key} />
                    <input type="hidden" name="hash" value={payload.hash} />
                    <input type="hidden" name="txnid" value={payload.txnid} />
                    <input type="hidden" name="amount" value={payload.amount} />
                    <input type="hidden" name="firstname" id="firstname" value={payload.firstname} />
                    <input type="hidden" name="email" id="email" value={payload.email} />
                    <input type="hidden" name="phone" value={payload.phone} />
                    <textarea type="hidden" name="productinfo" value={payload.productinfo} />
                    <input type="hidden" name="surl" size="64" value={payload.surl} />
                    <input type="hidden" name="furl" size="64" value={payload.furl} />
                    <input type="hidden" type="submit" value="Submit" onClick={this.submitForm()} />
                </form>
            </div>
        )
    }

    onSelectCourse = (selectedCourse) => {
        this.setState({ selectedCourse })
    }

    onCouponApply = (couponCode) => {
        if (!this.state.selectedUser) {
            this.setState({ isInvalidCoupon: true, couponErrorMessage: "Please select user" })
            return
        }

        if (!couponCode) {
            this.setState({ isInvalidCoupon: true, couponErrorMessage: "Please enter coupon code" })
            return
        }

        let payload = {
            coupon: couponCode,
            subscriptionPlanId: this.state.subscription._id,
            userIds: [this.state.selectedUser?._id],
        }

        PaymentAPI.applyCoupon(payload).then(response => {
            if (response && response.data) {
                this.setState({
                    isInvalidCoupon: false,
                    couponCode,
                    couponId: response.data.couponId,
                    couponAmount: Number(response.data.discountAmount || 0),
                    isCouponApplied: true,
                    blockUsersAdd: true
                })
                this.activityId = response.data.activityId
            } else {
                this.setState({
                    isInvalidCoupon: true,
                    couponId: "",
                    blockUsersAdd: false
                })
            }
        }).catch(err => {
            this.setState({
                isInvalidCoupon: true,
                couponErrorMessage: "Invalid Coupon",
                couponId: "",
                blockUsersAdd: false
            })
        })
    }

    onRemoveCoupon = () => {
        this.setState({
            couponErrorMessage: "",
            couponCode: "",
            couponId: "",
            couponAmount: 0,
            isCouponApplied: false,
            blockUsersAdd: false
        })
    }

    renderOffer() {
        return (
            <div className="parent-box pt-5">
                <div className="child-box">
                    <div className="cw-card custom-card-size">
                        <div className="offer-card p-2">
                            <div className="background">
                                <img src={OfferBackground} />
                            </div>
                            <div className="row">
                                <div className="col-2 text-right my-auto p-0">
                                    <img className="giftbox" src={Gift} />
                                </div>

                                <div className="col py-2" >
                                    <div className="offer-title ">Congratulations {this.state.user?.name}!</div>
                                    <div className="offer-content pt-1">{this.state.offerText}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderSubscriptionPlan() {
        return (
            <>
                <div className={"shadow-card"}>
                </div>

                <div className="subscription-card custom-card-size">
                    <div><img className="close-icon float-right" src={CloseIcon} onClick={this.hidePlans} /></div>
                    <div>Select Plan</div>
                    {
                        this.state.subscriptions?.map((item, index) => (
                            <div key={item?._id} className={`plan-card row py-4 my-2 cursor-pointer ${(index + 1) / 2 == 1 ? "plan-card-2" : ""}`} onClick={() => this.onSelectSubscription(item)}>
                                <div className="col-7">
                                    <div className="plan-name">{item.planName}</div>
                                    <div className="plan-description">{`Valid for ${item?.numberOfDays} days`}</div>
                                </div>
                                <div className="col-5 my-auto">
                                    <div className="amount">{item?.currency?.symbol || "₹"}{this.getFormattedAmount(item?.discountedPrice)}</div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </>
        )
    }

    renderDiscountCode() {
        return (

            <div className="discount py-4">
                <div>Discount Code</div>
                <div className="coupon">
                    <div>

                        <div className={`d-inline-block`}>
                            <input className={`cw-input uppercase ${this.state.isInvalidCoupon || this.props.isInvalidCoupon ? "invalid" : ""}`}
                                placeholder='Enter Discount Code'
                                onChange={(e) => this.onChangeHandler(e)}
                                value={this.state.couponCode} />
                        </div>

                        <div className={`apply cursor-pointer d-inline-block px-2 ${this.state.isInvalidCoupon || this.props.isInvalidCoupon ? "apply-invalid invalid" : ""}`} onClick={() => this.onCouponApply()}>
                            <span className="">Apply</span>
                        </div>

                        <div>{this.state.isInvalidCoupon || this.props.isInvalidCoupon ? <span className="error-msg">{this.props.errorMessage || "Invalid coupon code"}</span> : ""}</div>
                    </div>
                </div>
            </div>
        )
    }
    renderExpiredCard() {
        return (
            <div className="expired-card pt-5 px-4 pb-2">

                <div className="expired-image text-center">
                    <img src={Expired} />
                </div>

                <div className="opps pt-4" >
                    Opps!
                </div>

                <div className="text-1">
                    {`Your Scholarship Discount code is expired & no more valid now.`}
                </div>

                <div className="footer text-center">
                    <div className="upgrade text-center">Upgrade your account</div>
                    <div className="text-center pt-4">
                        <a href="https://countingwell.com/premium_subscription.html" className={`cw-button btn btn-md`} onClick={() => this.onRedirctTo()}> CONTINUE</a>
                    </div>
                </div>
            </div>
        )
    }


    render() {

        return (

            <div className="course-subscribe">
                <div className="user-subscription-background">
                    <div className='header row no-gutters'>
                        <div className="col-6"> <a href="https://countingwell.com"> <img alt="" className="logo" src={HeaderLogo} /> </a></div>
                    </div>
                    {
                        !this.state.loading ?
                            <div>
                                {this.state.showForm ? this.onShowForm(this.state.payload) : ""}

                                {
                                    !this.state.expired && this.state.offerText ? this.renderOffer() : ""
                                }
                                <div className="parent-box pt-3">
                                    <div className="child-box pb-4">
                                        <div className="cw-card custom-card-size">
                                            {
                                                !this.state.expired ?
                                                    <div>
                                                        <div className='cw-card-header pt-4 text-center'>
                                                            <h5><b>{this.state.subscription?.planName}</b></h5>
                                                            {this.state.discountedText ? <div className="text-1 pt-2">{this.state.discountedText}</div> : ""}
                                                            {this.state.alertText ? <div className="hurry"> {this.state.alertText} </div> : ""}
                                                        </div>
                                                        <div className="cw-card-body position-relative">

                                                            {
                                                                this.state.users?.length ?
                                                                    <div>
                                                                        <div className="student-list">
                                                                            {
                                                                                this.state.users.map(user => (
                                                                                    <div className={`row student pt-2 ${!user.isSubscribed ? "cursor-pointer" : ""} `} onClick={() => this.onClickUser(user)}>
                                                                                        <div className="col-8 flex overflow-hidden">
                                                                                            <div className="d-inline-block pr-3">

                                                                                                {
                                                                                                    this.state.selectedUser?._id === user._id ?
                                                                                                        <FontAwesomeIcon color="green" icon={faCheckCircle} />
                                                                                                        :
                                                                                                        <img alt="" className="ellipse" src={Ellipse} />

                                                                                                }
                                                                                            </div>

                                                                                            <div className="d-inline-block">
                                                                                                <small><b>{user.name}</b></small><br />
                                                                                                {user.alternateBoard || user.board ? <small>{user.alternateBoard ? user.alternateBoard : user.board}, {user.class}</small> : ""}
                                                                                            </div>

                                                                                        </div>

                                                                                        {/* <div className="col text-center p-0">
                                                                                    <div className="subscribed d-inline-block">{
                                                                                        user.isSubscribed ? <div className="subscribed-tag text-right"><img src={SubscribedImage} /></div> : ""
                                                                                    }</div>
                                                                                </div> */}
                                                                                    </div>))
                                                                            }

                                                                        </div>
                                                                        <div className="py-5">
                                                                            <SubscriptionAmount
                                                                                name={"One time Payment"}
                                                                                basePrice={this.state.subscription?.basePrice}
                                                                                discountedPrice={this.state.subscription?.discountedPrice}
                                                                                subTitle={`Valid for lifetime`}
                                                                                couponAmount={this.state.couponAmount}
                                                                                // couponAmount={this.state.couponAmount}
                                                                                isCouponApplied={this.state.isCouponApplied}
                                                                                isInvalidCoupon={this.state.isInvalidCoupon}
                                                                                errorMessage={this.state.couponErrorMessage}
                                                                                onClickViewPlan={() => this.setState({ showPlans: true })}
                                                                                currency={this.state.subscription?.currency?.symbol}
                                                                                myCountryPrice={this.state.myCountryPrice}
                                                                                myCurrency={this.state.myCurrency}
                                                                                indiaAmount={this.state.indiaAmount}
                                                                                onSelectCourse={this.onSelectCourse}
                                                                                addOn={this.state.subscription.addOn}
                                                                                onCouponApply={this.onCouponApply}
                                                                                onRemoveCoupon={this.onRemoveCoupon}
                                                                            />
                                                                        </div>

                                                                        {
                                                                            this.state.errorMessage ? <div className="p-2 text-center"> <span className="error-message text-danger">{this.state.errorMessage}</span> </div> : ""
                                                                        }

                                                                        {
                                                                            this.state.subscription && this.state.subscription._id ?
                                                                                <div>
                                                                                    {
                                                                                        this.state.users ?
                                                                                            <div className="text-center">
                                                                                                <div className="gst-text p-2">Price mentioned is inclusive of GST</div>
                                                                                                <span className={`cw-button btn btn-md ${!this.state.selectedUser ? "disable-subscribe-button" : ""}`} onClick={() => this.onSubscribe()}> CONTINUE</span>
                                                                                            </div>
                                                                                            : ""
                                                                                    }
                                                                                </div>
                                                                                : ""
                                                                        }
                                                                    </div>
                                                                    :
                                                                    <div className="pt-5 text-center">
                                                                        <AppLink />
                                                                    </div>
                                                            }
                                                        </div>

                                                    </div> : this.renderExpiredCard()}
                                            {/* {
                                                this.state.showPlans ? this.renderSubscriptionPlan() : ""
                                            } */}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            : ""
                    }
                </div>
            </div>
        );
    }

}

export default UsersListSubscrption;