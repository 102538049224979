const validateEmail = (value) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,50})+$/;
    if (reg.test(value) === false) {
        return false
    } else {
        return true
    }
}

const getDoubtTime = (timeStamp) => {

    var d = new Date();

    let tempTime = d.setHours(d.getHours() - 24); /** 24 hours before */

    if (tempTime > timeStamp) {
        let dateFormat = new Date(timeStamp).toDateString();

        let date = dateFormat.split(" ")[2];

        let month = dateFormat.split(" ")[1];

        let year = dateFormat.split(" ")[3];

        let finalDate = date + " " + month + " " + year;

        return finalDate
    } else {

        let date1 = new Date(timeStamp);

        let date2 = new Date();

        var res = Math.abs(date1 - date2) / 1000;

        var days = Math.floor(res / 86400);

        // get hours
        var hours = Math.floor(res / 3600) % 24;

        // get minutes
        var minutes = Math.floor(res / 60) % 60;

        // get seconds
        var seconds = res % 60;

        if (hours)
            return hours + ' Hours ago'

        if (minutes)
            return minutes + ' Mins ago'

        if (seconds)
            return Math.round(seconds) + ' Seconds ago'

    }

}

const validateMobileNumber = (mobileNumber, validationDetails = {}) => {

    if (!isNumeric(mobileNumber)) {
        return false
    }

    var numbers = /^[0-9]+$/;

    if (!mobileNumber.match(numbers)) {
        return false
    }

    let maxLength = validationDetails.length;
    let minLength = validationDetails.minLength;

    if (!maxLength || mobileNumber.length == 0) {
        return true;
    } else if (minLength && mobileNumber.length >= minLength && mobileNumber.length <= maxLength) {
        return true;
    } else if (mobileNumber.length == maxLength) {
        return true;
    } else {
        return false;
    }

}

const isNumeric = (str) => {
    return !isNaN(str);
}


module.exports = {
    validateEmail,
    getDoubtTime,
    validateMobileNumber
}