import HttpClient from './http-client';
import URL from './url';

export default class AuthenticateAPI {

    static sendOtp(payload) {
        return HttpClient.post(URL.BASE_URL + URL.SEND_OTP, null, payload);
    }

    static sendSignupOtp(payload) {
        return HttpClient.post(URL.BASE_URL + URL.SEND_SINGUP_OTP, null, payload);
    }

    static signup(payload) {
        return HttpClient.post(URL.BASE_URL + URL.SING_UP, null, payload);
    }

    static sendOtpForSchoolCoupon(payload) {
        return HttpClient.post(URL.BASE_URL + URL.SEND_OTP_SCHOOL_COUPON_LOGIN, null, payload);
    }

    static verifyOtp(payload) {
        return HttpClient.post(URL.BASE_URL + URL.VERIFY_OTP, null, payload);
    }

    static verifySignupOtp(payload) {
        return HttpClient.post(URL.BASE_URL + URL.VERIFY_SINGUP_OTP, null, payload);
    }

    static verifyUserOtp(payload) {
        return HttpClient.post(URL.BASE_URL + URL.VERIFY_USER_OTP, null, payload);
    }

    static isLoggedIn(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.IS_LOGGEDIN + "?" + params.join('&') : URL.IS_LOGGEDIN;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static getCountyList(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.COUNTRY_LIST + "?" + params.join('&') : URL.COUNTRY_LIST;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static getCities(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.CITIES_LIST + "?" + params.join('&') : URL.CITIES_LIST;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

}
