import React from 'react';
import "./users-subscribe.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faSquare } from '@fortawesome/free-solid-svg-icons'
import PaymentAPI from '../../apis/payment'
import SubscriptionPlanAPI from '../../apis/subscription-plan'
import CouponAPI from '../../apis/coupon'
import UsersAPI from '../../apis/users'
import Divider from '../../assets/images/divider.png'
import SubscribedImage from '../../assets/images/subscribed-image.png'
import AppLink from '../app-link/app-link'
import HeaderLogo from '../../assets/images/logo.png'
import Ellipse from '../../assets/images/ellipse.png'
import SubscriptionAmount from './subscription-amount/subscription-amount'
import { Constants } from '../../constants/constants'

class UsersListSubscrption extends React.Component {
    planId = ""
    mobileNumber = ''
    state = {
        subscriptionList: [],
        users: [],
        selectedUsers: [],
        subscription: {},
        isInfluenceCoupon: false,
        couponAmount: 0,
        coupon: {},
        myCountryPrice: "",
        indiaAmount: ""
    }

    constructor(props) {
        super(props)
    }

    componentDidMount() {

        let urlParams = new URLSearchParams(window.location.search);
        this.planId = urlParams.get('plan')
        this.couponId = urlParams.get('cid')
        this.mobileNumber = urlParams.get('m')
        this.countryCode = urlParams.get('cc')
        this.otp = urlParams.get('o')
        this.userName = urlParams.get('un')

        console.log(this.mobileNumber)

        if (this.couponId) {
            this.setState({
                isInfluenceCoupon: true,
                couponId: this.couponId,
                isCouponApplied: true,
            })
        }

        this.getUsers()
    }

    getCurreny(baseCurrency = "USD", amount) {

        SubscriptionPlanAPI.callAbstractApi(Constants.ABSTRACT_LOCATION_API).then((response) => {
            let targetCurreny = response?.currency?.currency_code || "INR"
            if (baseCurrency == targetCurreny) return
            let url = `${Constants.ABSTRACT_EXCHANGE_RATE_API}&base=${baseCurrency}&target${targetCurreny}`
            SubscriptionPlanAPI.callAbstractApi(url).then((response) => {
                let targetAmount = response.exchange_rates ? response.exchange_rates[targetCurreny] : ""
                let indiaAmount = response.exchange_rates ? response.exchange_rates["INR"] : ""
                amount = amount * targetAmount
                this.setState({ myCountryPrice: targetAmount, baseCurrency, myCurrency: targetCurreny, indiaAmount })
            }).catch((err) => {
                this.setState({ myCountryPrice: "" })
            })
        }).catch((err) => {
        })
    }

    onSelectStudent(user) {
        if (user.isSubscribed) return
        if (this.state.blockUsersAdd) return

        if (this.state.subscription?.recurring?.isRecurring) {
            this.setState({ selectedUsers: [user._id] })
            return
        }

        let users = []
        if (this.state.selectedUsers.includes(user._id)) {
            users = this.state.selectedUsers.filter(userId => userId !== user._id)
            this.setState({ selectedUsers: users })
        } else {
            users = [...this.state.selectedUsers, user._id]
            this.setState({ selectedUsers: users })
        }

        if (this.state.isInfluenceCoupon) {
            let { totalAmount, discountAmount } = this.calculateDiscountAmount(users)
            this.setState({ couponAmount: discountAmount })
        }
    }


    getUsers() {

        let payload = {}

        if(this.otp) {
            payload.otp = this.otp
        }

        if(this.userName) {
            payload.userName = this.userName
        }


        UsersAPI.getUsers(payload).then((response) => {
            let data = response.data
            if (data.length) {
                this.setState({ users: data }, () => {
                    this.getSubscription()
                })
            } else {
                this.setState({ users: [] })
            }
        }).catch((err) => {
            alert("Something went wrong");
        })
    }

    getSubscription() {
        if (!this.planId) return
        let payload = { planId: this.planId }
        SubscriptionPlanAPI.getSubscriptions(payload).then((response) => {
            let data = response.data
            let courses = response.courses || []
            if (data && data.length) {
                this.setState({ subscription: data[0], courses })
                let curreny = data[0]?.currency?.code
                this.getCurreny(curreny)
                if (this.couponId) {
                    this.getCoupon()
                }

            } else {
                this.setState({ subscription: {}, courses })
            }
        }).catch((err) => {
        })
    }

    getCoupon() {
        if (!this.couponId) return
        let payload = { couponId: this.couponId }
        CouponAPI.getCoupons(payload).then((response) => {
            let data = response.response
            if (data && data.length) {
                this.setState({
                    coupon: data[0],
                    couponCode: data[0].couponCode,
                }, () => {
                    this.setDefaultSelectedUser()
                })
            }
        }).catch((err) => {
            console.log(err)
        })
    }


    setDefaultSelectedUser() {
        if (!this.state.users && !this.state.users.length) return

        let selectedUsers = []
        for (let i = 0; i < this.state.users.length; i++) {
            if (!this.state.users[i].isSubscribed) {
                selectedUsers.push(this.state.users[i]._id)
            }
        }
        this.setState({ selectedUsers })
        if (this.state.isInfluenceCoupon) {
            let { totalAmount, discountAmount } = this.calculateDiscountAmount(selectedUsers)
            this.setState({ couponAmount: discountAmount })
        }
    }

    calculateDiscountAmount(users) {

        if (!users.length) {
            return { totalAmount: this.state.subscription.discountedPrice, discountAmount: 0 }
        }

        let totalAmount = (this.state.subscription.discountedPrice) * users.length

        let discountAmount = 0

        if (this.state.coupon.minimumOrder && Number(this.state.coupon.minimumOrder.amount || 0) > totalAmount) {
            this.setState({ disableContinue: true, errorMessage: `Minimum order should be Rs. ${this.state.coupon.minimumOrder.amount}` })
            return { totalAmount, discountAmount }
        }

        if (this.state.coupon.discount.type == Constants.DISCOUNT_TYPE.AMOUNT) {
            discountAmount = this.state.coupon.discount.value
        }

        if (this.state.coupon.discount.type == Constants.DISCOUNT_TYPE.PERCENTAGE) {
            discountAmount = (totalAmount * this.state.coupon.discount.value) / 100
        }
        this.setState({ errorMessage: "", disableContinue: false, })
        return { totalAmount, discountAmount }
    }

    onSubscribe = () => {
        if (this.state.isCreatingHash) return
        if (!this.state.subscription || !this.state.subscription._id) return
        if (!this.state.selectedUsers.length || this.state.disableContinue) return

        let payload = {
            subscriptionId: this.state.subscription._id,
            usersId: this.state.selectedUsers,
        }

        if (this.state.couponId && this.state.couponAmount > 0) {
            payload.couponId = this.state.couponId
            payload.coupon = this.state.couponCode
            payload.activityId = this.activityId
            payload.couponType = this.state.isInfluenceCoupon ? Constants.COUPON_TYPE.INFLUENCER : Constants.COUPON_TYPE.PUBLIC
        }

        if (this.state.selectedCourse?.length) {
            let addOn = []
            this.state.selectedCourse.map(element => {
                let obj = {
                    subscriptionId: element._id,
                }

                if (element.courseId) {
                    obj.courseId = element.courseId
                }

                addOn.push(obj)
            })
            payload.addOn = addOn
        }

        this.setState({ isCreatingHash: true })

        PaymentAPI.getHash(payload).then((response) => {
            let payload = response.data?.hash
            if (payload) {
                this.setState({ showForm: true, payload, isCreatingHash: false, errorMessage: "" })
            }
        }).catch((err) => {
            if (err.response) {
                if (err.response.status === 900) {
                    this.setState({ errorMessage: "Invalid Coupon" })
                }
            }
            this.setState({ isCreatingHash: false, })
            alert("Something went wrong");
        })
    }

    onCouponApply = (couponCode) => {
        if (!this.state.selectedUsers.length) {
            this.setState({ isInvalidCoupon: true, couponErrorMessage: "Please select user" })
            return
        }

        if (!couponCode) {
            this.setState({ isInvalidCoupon: true, couponErrorMessage: "Please enter coupon code" })
            return
        }

        let payload = {
            coupon: couponCode,
            subscriptionPlanId: this.state.subscription._id,
            userIds: this.state.selectedUsers,
        }

        PaymentAPI.applyCoupon(payload).then(response => {
            if (response && response.data) {
                this.setState({
                    isInvalidCoupon: false,
                    couponCode,
                    couponId: response.data.couponId,
                    couponAmount: Number(response.data.discountAmount || 0),
                    isCouponApplied: true,
                    blockUsersAdd: true
                })
                this.activityId = response.data.activityId
            } else {
                this.setState({
                    isInvalidCoupon: true,
                    couponId: "",
                    blockUsersAdd: false
                })
            }
        }).catch(err => {
            this.setState({
                isInvalidCoupon: true,
                couponErrorMessage: "Invalid Coupon",
                couponId: "",
                blockUsersAdd: false
            })
        })
    }

    onRemoveCoupon = () => {
        this.setState({
            couponErrorMessage: "",
            couponCode: "",
            couponId: "",
            couponAmount: 0,
            isCouponApplied: false,
            blockUsersAdd: false
        })
    }

    submitForm() {
        setTimeout(() => {
            document.payuform.submit();
        }, 100)
    }

    onSelectCourse = (selectedCourse) => {
        this.setState({ selectedCourse })
    }

    onShowForm = (payload) => {
        return (
            <div style={{ "display": "none" }}>
                <form action={payload.actionUrl} name="payuform" id="payuform" method="POST" >
                    <input type="hidden" name="key" value={payload.key} />
                    <input type="hidden" name="hash" value={payload.hash} />
                    <input type="hidden" name="txnid" value={payload.txnid} />
                    <input type="hidden" name="amount" value={payload.amount} />
                    <input type="hidden" name="firstname" id="firstname" value={payload.firstname} />
                    <input type="hidden" name="email" id="email" value={payload.email} />
                    <input type="hidden" name="phone" value={payload.phone} />
                    <input type="hidden" name="productinfo" value={payload.productinfo} />
                    <input type="hidden" name="surl" size="64" value={payload.surl} />
                    <input type="hidden" name="furl" size="64" value={payload.furl} />
                    {payload.isRecurring ? <input type="hidden" name="api_version" value={payload?.api_version || 7} /> : ""}
                    {payload.isRecurring ? <input type="hidden" name="si" value={payload.si || 1} /> : ""}
                    {payload.isRecurring ? <input type="hidden" name="si_details" value={payload.si_details} /> : ""}
                    {payload.pg ? <input type="hidden" name="pg" value={payload.pg} /> : ""}
                    <input type="hidden" type="Submit" onClick={this.submitForm()} />
                </form>
            </div>
        )
    }


    render() {

        return (

            <div className="user-subscribe">
                <div className="user-subscription-background">
                    <div className='header row no-gutters'>
                        <div className="col-6"> <a href="https://countingwell.com"> <img alt="" className="logo" src={HeaderLogo} /> </a></div>
                    </div>
                    {this.state.showForm ? this.onShowForm(this.state.payload) : ""}
                    <div className="parent-box pt-5">
                        <div className="child-box">
                            <div className="cw-card custom-card-size">
                                <div className='cw-card-header pt-4 text-center'>
                                    <h5><b>Hey!</b></h5>
                                    {
                                        this.state.users.length ?
                                        <div>
                                             <div className="text-1 pt-2">Following Students are linked with this account</div>
                                             <div className="text-1 pt-2 button-bg">Select the Students You want to pay for : </div>
                                        </div>
                                            
                                            :
                                            <div className="text-1 pt-2">No Student linked to this account, Please download the App and create student Signup first</div>

                                    }
                                </div>
                                <div className="cw-card-body">

                                    {
                                        this.state.users.length ?
                                            <div>
                                                <div className="student-list">
                                                    {
                                                        this.state.users.map((user, index, userData) => (
                                                            <div className={`row student pt-2 ${!user.isSubscribed ? "cursor-pointer" : ""} `} onClick={() => this.onSelectStudent(user)}>
                                                                <div className="col-8 flex overflow-hidden">
                                                                    <div className="d-inline-block pr-3">

                                                                        {
                                                                            this.state.selectedUsers.includes(user._id) ?
                                                                                <FontAwesomeIcon color="green" icon={faCheckCircle} />
                                                                                :
                                                                                user.isSubscribed ?
                                                                                    <FontAwesomeIcon color="gray" icon={faCheckCircle} />
                                                                                    :
                                                                                    <img className="ellipse" src={Ellipse} />

                                                                        }
                                                                    </div>

                                                                    <div className="d-inline-block">
                                                                        <small><b>{user.name}</b></small><br />
                                                                        {user.alternateBoard ? <small>{user.alternateBoard ? user.alternateBoard : user.board}, {user.class}</small> : ""}
                                                                    </div>

                                                                </div>

                                                                <div className="col text-center p-0">
                                                                    <div className="subscribed d-inline-block">{
                                                                        user.isSubscribed ? <div className="subscribed-tag text-right"><img src={SubscribedImage} /></div> : ""
                                                                    }</div>
                                                                </div>
                                                                {
                                                                    index !== userData.length - 1 ?
                                                                        <img className="divider-image pt-2" src={Divider} /> : ""
                                                                }
                                                            </div>

                                                        ))
                                                    }
                                                </div>
                                                <div className="py-5">
                                                    {
                                                        this.state.subscription && this.state.subscription._id && this.state.users.some(user => !user.isSubscribed) ?

                                                            <SubscriptionAmount
                                                                name={this.state.subscription.planName}
                                                                basePrice={this.state.selectedUsers.length ? this.state.subscription.basePrice * this.state.selectedUsers.length : this.state.subscription.basePrice}
                                                                discountedPrice={this.state.selectedUsers.length ? this.state.subscription.discountedPrice * this.state.selectedUsers.length : this.state.subscription.discountedPrice}
                                                                subTitle={this.state.subscription.subTitle}
                                                                couponAmount={this.state.couponAmount}
                                                                isCouponApplied={this.state.isCouponApplied}
                                                                isInvalidCoupon={this.state.isInvalidCoupon}
                                                                errorMessage={this.state.couponErrorMessage}
                                                                restrictEdit={this.state.isInfluenceCoupon}
                                                                onCouponApply={this.onCouponApply}
                                                                onRemoveCoupon={this.onRemoveCoupon}
                                                                currency={this.state.subscription?.currency?.symbol || "₹"}
                                                                myCountryPrice={this.state.myCountryPrice}
                                                                myCurrency={this.state.myCurrency}
                                                                indiaAmount={this.state.indiaAmount}
                                                                courses={this.state.courses}
                                                                onSelectCourse={this.onSelectCourse}
                                                                usersCount={this.state.selectedUsers?.length || 1}
                                                            />
                                                            :
                                                            ""
                                                    }
                                                </div>

                                                {
                                                    this.state.errorMessage ? <div className="p-2 text-center"> <span className="error-message text-danger">{this.state.errorMessage}</span> </div> : ""
                                                }

                                                {
                                                    this.state.subscription && this.state.subscription._id ?
                                                        <div>
                                                            {
                                                                this.state.users.length && this.state.users.some(user => !user.isSubscribed) ?
                                                                    <div className="text-center">
                                                                        <div className="gst-text p-2">Price mentioned is inclusive of GST</div>
                                                                        <span className={`cw-button btn btn-md ${!this.state.selectedUsers.length || this.state.disableContinue ? "disable-subscribe-button" : ""}`} onClick={() => this.onSubscribe()}> CONTINUE</span>
                                                                    </div>
                                                                    : ""
                                                            }
                                                        </div>
                                                        : ""
                                                }
                                            </div>
                                            :
                                            <div className="pt-5 text-center">
                                                <AppLink />
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default UsersListSubscrption;