import React from 'react';
import "./welcome.scss";
import Header from "../common/header/header-component";
import { Constants, ImageConstants } from '../../constants/constants';
import { getImageSource } from '../../utility/image-source'
import SessionCache from '../routes/session-cache';

class Welcome extends React.Component {


    render() {

        return (

            <div className="welcome">
                <div className="welcome-background">
                    <Header transparent={true}></Header>
                    <div className="row no-gutters">
                        <div className="col-6">
                            <div className="left-padding cw-mt-8">
                                <p className='user-name-text'>Hey {SessionCache.getName() ? SessionCache.getName() : ""}</p>
                                <p className='user-name-text'>Your role is {SessionCache.getRole()}</p>
                                <p className='message-text'>{Constants.WELCOME}</p>
                                <p className='message-text'>{Constants.APPROVAL_TEXT} <strong>{Constants.COUNTINGWELL_ADMIN}</strong> </p>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="welcome-banner cw-mt-8">
                                <img className='welcome-image' src={getImageSource(ImageConstants.WELCOME)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default Welcome;