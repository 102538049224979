import React from 'react';
import './App.scss';
import Routes from './home/components/routes/routes';

function App() {

  return (
    <Routes></Routes>
  );
}


export default App;
