import React from 'react'
import { Redirect, Router, Switch } from 'react-router-dom'
import Route from './route-wrapper';

import Login from '../login/login-v2';
import Path from './routes-path';

import history from './history';
import Welcome from '../welcome/welcome';
import UserSubscribe from '../users-subscribe/users-subscribe';
import Scholarship from '../scholarship/scholarship';
import ExamPrepKitScholarship from '../exam_prep_scholarship/scholarship';
import PaymentSuccess from '../payment-response/success';
import PaymentFail from '../payment-response/fail';
import SchoolCouponOfferScreen from '../school-coupon/offer-screen/school-coupon';
import SchoolCouponLogin from '../school-coupon/login/login';
import UserList from '../school-coupon/user-list/user-list';
import Signup from '../sign-up/sign-up';
import CourseSubscribe from '../course-subscribe/course-subscribe';


export default function Routes() {

  return (
    <Router history={history}>
      <Switch>
        <Route exact path={Path.LOGIN} component={Login} />
        {/* <Route exact path={Path.SUBSCRIPTION} component={Subscription} /> */}
        <Route exact path={Path.WELCOME} component={Welcome} />
        <Route exact path={Path.PAYMENT_SUCCESS} component={PaymentSuccess} />
        <Route exact path={Path.PAYMENT_FAIL} component={PaymentFail} />
        <Route exact path={Path.USER_SUBSCRIBE} component={UserSubscribe} />
        <Route exact path={Path.SCHOLARSHIP} component={Scholarship} />
        <Route exact path={Path.EXAM_PREP_KIT_SCHOLARSHIP} component={ExamPrepKitScholarship} />
        <Route exact path={Path.SCHOOL_COUPON_OFFER} component={SchoolCouponOfferScreen} />
        <Route exact path={Path.SCHOOL_COUPON_LOGIN} component={SchoolCouponLogin} />
        <Route exact path={Path.USER_LIST} component={UserList} />
        <Route exact path={Path.SIGN_UP} component={Signup} />
        <Route exact path={Path.COURSE} component={CourseSubscribe} />
        <Route path="*" >
          <Redirect to={Path.LOGIN} /> </Route>
      </Switch>
    </Router>
  )
}
