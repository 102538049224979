const ROOT = '';
const Path = {

    LOGIN: ROOT + "/login",
    SIGN_UP: ROOT + "/signup",
    WELCOME: ROOT + '/welcome',
    USER_SUBSCRIBE: ROOT + '/user-subscribe',
    COURSE: ROOT + '/course',
    SUBSCRIPTION: ROOT + '/subscription',
    PAYMENT_SUCCESS: ROOT + '/payment/success',
    PAYMENT_FAIL: ROOT + '/payment/fail',
    SCHOLARSHIP: ROOT + '/scholarship/subscription',
    EXAM_PREP_KIT_SCHOLARSHIP: ROOT + '/examprepkitscholarship/subscription',
    SCHOOL_COUPON_OFFER: ROOT + '/school-coupon/offer',
    USER_LIST: ROOT + '/user-list',
    SCHOOL_COUPON_LOGIN: ROOT + '/school-coupon/login',
};

export default Path;