export const Constants = {

    MATH_STARTING_TAG: "<cw-math>",
    MATH_ENDING_TAG: "</cw-math>",

    MAX_GRAGE_LIMIT: 5,
    SLOGAN: "Countingwell Users",
    WELCOME: 'Welcome to Countingwell',
    PERFORMANCE_REPORT: 'Performance Report',
    ELO_SUMMARY_REPORT: 'ELO Summary Report',
    ELO_PERFORMANCE_BY_STUDENTS: "ELO Performances -By Student",
    STREAMING_REPORT: "Streaming Report",
    LOGOUT: 'Logout',
    APPROVAL_TEXT: 'You could access the reports once your account is approved by your',
    COUNTINGWELL_ADMIN: 'Countingwell Admin',

    ABSTRACT_LOCATION_API: "https://ipgeolocation.abstractapi.com/v1/?api_key=d1348f7124084e22aa8aa0fb652d84e8",
    ABSTRACT_EXCHANGE_RATE_API: "https://exchange-rates.abstractapi.com/v1/live/?api_key=a3cf9a4e6e5e41f5a95b935a2251bd12",

    ACTIVE_TAB_SIGN_UP: 0,
    ACTIVE_TAB_LOGIN: 1,

    /** Forgot password flow */
    SEND_EMAIL_PAGE: 1,
    RESET_PASSWORD_PAGE: 2,
    RESET_PASSWORD_SUCCESS_PAGE: 3,

    INVALID_EMAIL_ID: 1500,

    CLASS_LIMIT: 100,
    SECTIONS_LIMIT: 100,
    SCHOOL_LIMIT: 100,

    REPORT_TYPE_ELO_PERFORMANCE: 1,
    REPORT_TYPE_ELO_SUMMARY: 2,
    REPORT_TYPE_STUDENTS_PERFORMANCE: 3,
    REPORT_TYPE_STUDENTS_STREAMING: 4,

    FB_PIXEL_ID: "252975119560551",
    GTM_ID: 'GTM-MTHJNTL',

    STUDENTS_STATUS: [{ title: "Started", value: "1" }, { title: "Not Started", value: "2" }],

    STUDENTS_STATUS_STARTED: "STARTED",
    STUDENTS_STATUS_NOT_STARTED: "NOT_STARTED",

    COMMENT_TOOLBAR: [{ name: 'formula', items: ['cwmath', 'Mathjax',] }],
    CUSTOM_MATH_KEYBOARD_SYMBOLS: [
        {
            class: "keycap",
            latex: "\\triangle"
        },

        {
            class: "keycap",
            latex: "\\angle",
        },
        {
            class: "keycap",
            latex: "\\parallel",
        },
        {
            class: "keycap",
            latex: "\\perp",
        },
        {
            class: "keycap",
            latex: "\\cong",
        },
        {
            class: "keycap",
            latex: "\\div",
        },
        {
            class: "keycap",
            latex: '^{\\circ}'
        }
    ],

    SUBSCRIPTION_START_EVENT: 'SUBSCRIPTION_START',
    SUBSCRIPTION_SUCCESS_EVENT: 'SUBSCRIPTION_SUCCESS',
    SUBSCRIPTION_FAIL_EVENT: 'SUBSCRIPTION_FAIL',

    DISCOUNT_TYPE: {
        PERCENTAGE: "PERCENTAGE",
        AMOUNT: "AMOUNT"
    },

    COUPON_TYPE: {
        PRIVATE: "PRIVATE",
        PUBLIC: "PUBLIC",
        INFLUENCER: "INFLUENCER",
    },
};


export const ImageConstants = {
    DOWN_CARET_DROPDOWN: 'down-caret-dropdown',
    CW_LOGO: 'cw-logo',
    CW_IMAGE: "cw-image",
    WELCOME: "welcome",
    PERFORMANCE_REPORT: 'performance',
    ELO_SUMMARY_REPORT: 'elo-summary',
    ELO_PERFORMANCE_BY_STUDENTS: "student-performance",
    STREAMING_REPORT: "streaming-report",
    HEADER_LOGO: 'header-logo',
    USER_PLACEHOLDER: 'user',
    LOGOUT: "logout",
    REMOVE_CIRCLE: 'remove-circle',
    FORGOT_PASSWORD: 'forgot-password',
    APP_DOWNLOAD_QR_CODE : 'app_download_qr_code',

    REPORT_BG_1: 'report-bg1',
    REPORT_BG_2: 'report-bg2',

    HR_LINE: 'line',

    CLS_PERFORMANCE_TOTAL_ELOS: 'total-elos',
    CLS_PERFORMANCE_ELOS_ATTEMPTED: 'elos-attempted',
    CLS_PERFORMANCE_AVG_ATTEMPTED_PER_ELO: 'avg-attempted-per-elo',
    CLS_PERFORMANCE_AVG_TIME_PER_ELO: 'avg-time-per-elo',
    CLS_PERFORMANCE_TOTAL_STUDENTS: 'total-students',
    CLS_PERFORMANCE_STUDENT_ATTEMPTED: 'student-attempted',
    DROPDOWN_ARROW: 'dropdown-arrow',
    LOADER_HEADER: 'loader-header',
    LOADER_FOOTER: 'loader-footer',
    LOADING_GIF: 'loading-gif',
    PROFILE_VECTOR: 'profile-vector',

    REPORT_WELCOME_ICON: 'report-welcome-icon',
    EXCEL_ICON: 'excel',
    CLEAR_ICON: 'clear'

}

