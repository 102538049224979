import HttpClient from './http-client';
import URL from './url';

export default class UsersAPI {

    static getUsers(payload = {}) {

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.USER_LIST + "?" + params.join('&') : URL.USER_LIST;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static getCourseUsers(payload = {}) {

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.COURSE_USER_LIST + "?" + params.join('&') : URL.COURSE_USER_LIST;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static getScholarshipUsers(payload = {}) {

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.SCHOLARSHIP + "?" + params.join('&') : URL.SCHOLARSHIP;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static getUsersForSchoolDiscount(payload = {}) {

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.SCHOOL_USER_LIST + "?" + params.join('&') : URL.SCHOOL_USER_LIST;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static connectToSchool(payload) {
        return HttpClient.post(URL.BASE_URL + URL.SCHOOL_USER_CONNECT, null, payload);
    }


    static getCourses(payload = {}) {

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.COURSE + "?" + params.join('&') : URL.COURSE;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }


    static getCourseList(payload = {}) {

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.COURSE_LIST + "?" + params.join('&') : URL.COURSE_LIST;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }
}
