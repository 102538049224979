import React from 'react';
import "./payment.scss";
import SuccessImage from '../../assets/images/success-image.png'
import CourseSuccess from '../../assets/images/course-success.png'
import HeaderLogo from '../../assets/images/logo.png'
import ReactPixel from 'react-facebook-pixel';
import { Constants, ImageConstants } from '../../constants/constants'
import TagManager from 'react-gtm-module'
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import branch from 'branch-sdk'
import serverConfig from '../../apis/server-config'
import AppLink from '../app-link/app-link';
import { getImageSource } from '../../utility/image-source';


let tagManagerArgs = {
    gtmId: Constants.GTM_ID
}

const firebaseConfig = {
    apiKey: "AIzaSyBOQaznope_EJxUcCiXhdYUBpo9XbWEtgM",
    authDomain: "countingwell-22064.firebaseapp.com",
    projectId: "countingwell-22064",
    storageBucket: "countingwell-22064.appspot.com",
    messagingSenderId: "434404415565",
    appId: "1:434404415565:web:b521ffe4ee74a59479e404",
    measurementId: "G-9V6QN94W27"
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

branch.init("key_live_gnJZ4YlUm4C9IEJsxE5OwpgmqvagHTST", () => {

})

TagManager.initialize(tagManagerArgs)

branch.init("key_live_gnJZ4YlUm4C9IEJsxE5OwpgmqvagHTST", () => {

})


class PaymentSuccess extends React.Component {
    state = {}
    constructor(props) {
        super()

        let search = window.location.search;
        let params = new URLSearchParams(search);
        let isCourse = params.get('isCourse')
        this.planId = params.get('planId')
        this.state.isCourse = isCourse == "true"
    }

    componentDidMount() {
        ReactPixel.init(Constants.FB_PIXEL_ID);
        ReactPixel.pageView()
        ReactPixel.track("Purchase", { value: 1, currency: "INR", content_name: "SUBSCRIPTION_SUCCESS" })

        let data = {
            event: 'conversion',
            data:
            {
                send_to: 'AW-433550469/YzI3CO79mv0BEIXp3c4B',
                transaction_id: this.getTransactionId()
            }
        }

        let event = serverConfig.EVENT_PREFIX_TEXT ? `${serverConfig.EVENT_PREFIX_TEXT}${Constants.SUBSCRIPTION_SUCCESS_EVENT}` : Constants.SUBSCRIPTION_SUCCESS_EVENT


        try {
            firebase.analytics().logEvent(event,
                {
                    name: this.getName(),
                    send_to: 'AW-433550469/YzI3CO79mv0BEIXp3c4B',
                    transaction_id: this.getTransactionId()
                }
            );
            console.log("SUcessfire")
        } catch (error) {
            console.log(error)
        }

        try {
            branch.logEvent(event, {
                name: this.getName(),
                send_to: 'AW-433550469/YzI3CO79mv0BEIXp3c4B',
                transaction_id: this.getTransactionId()
            })
            console.log("branchsuccess")
        } catch (error) {
            console.log(error)
        }

        try {
            TagManager.dataLayer(data);
            console.log("gtmdata", data)
        } catch (error) {
            console.log(error)
        }


    }

    getTransactionId() {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let transactionId = params.get('transactionId')

        return transactionId || ''
    }

    // autoLogout() {
    //     setTimeout(() => {
    //         Service.createData(Urls.LOGOUT, {}, (err, res) => {

    //             SessionCache.setUser({
    //                 isAuthenticated: false
    //             });
    //             this.setState({
    //                 isLogout: true,
    //             });
    //         })
    //     }, 3000)
    // }

    datePipe() {
        let params = this.props.location.search.substring(1)
        if (!params) return ""
        let deCode = decodeURIComponent(params)
        let [dateString] = deCode.split("&")
        let [key, validTill] = dateString.split("=")
        if (!validTill) return '-'
        return validTill
    }

    getName() {
        let params = this.props.location.search.substring(1)
        if (!params) return ""
        let deCode = decodeURIComponent(params)
        let [dateString, nameString] = deCode.split("&")
        let [key, name] = nameString.split("=")
        return name
    }

    render() {

        return (

            <div className="payment-response">

                <div className="payment-response-background">
                    <div className='header row no-gutters'>
                        <a href="https://countingwell.com"> <img alt="" className="logo" src={HeaderLogo} /> </a>
                    </div>

                    <div className="parent-box pt-5">
                        <div className="child-box  pb-4">
                            <div className='cw-card text-center'>
                                <div className='cw-card-body cw-card-size'>
                                    {
                                        !this.state.isCourse ?
                                            <div>
                                                <div className="icon-size pt-2"><img src={SuccessImage} /></div>
                                                <div className="response-success pt-4">{this.getName()}, Your Subscription is ACTIVE</div>
                                                <div className="pt-2 text-1">Valid till <b>{this.datePipe()}</b></div>
                                                <div className="pt-5 text-1"><b>Login</b> to App to access unlimited content</div>
                                                {
                                                    this.planId === "650adb104936dc2d62c00c39" ?
                                                        <>
                                                            <div className="next pt-4">What's Next</div>
                                                            <div className="download pt-4">Download the Countingwell App & start learning</div>
                                                            <div>
                                                                <AppLink isVertical />

                                                            </div>
                                                            <div className="download pt-4">Scan this QR code with your mobile phone to download the app</div>
                                                            <div>
                                                                <img className='qr-code' src={getImageSource(ImageConstants.APP_DOWNLOAD_QR_CODE)} />
                                                            </div>
                                                            
                                                        </>
                                                        : <></>
                                                }
                                            </div>
                                            :
                                            <div>
                                                <div className="icon-size pt-2"><img alt="" src={CourseSuccess} /></div>
                                                <div className="payment-success pt-4">Payment Successful</div>
                                                <div className="next pt-4">What's Next</div>
                                                <div className="download pt-4">Download the Countingwell App & start learning</div>
                                                <div>
                                                    <AppLink isVertical />

                                                </div>
                                            </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                </div>
            </div>
        );
    }

}

export default PaymentSuccess;