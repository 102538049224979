import HttpClient from './http-client';
import URL from './url';

export default class PaymentAPI {

    static getCoupons(payload = {}) {

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.COUPON_LIST + "?" + params.join('&') : URL.COUPON_LIST;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static getSchoolCoupons(payload = {}) {

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.SCHOOL_COUPON + "?" + params.join('&') : URL.SCHOOL_COUPON;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }
}

