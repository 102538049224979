import React from 'react';
import Service from '../../apis/service-manager';
import Urls from '../../apis/url';
import { Route, Redirect } from 'react-router-dom';
import SessionCache from './session-cache';
import Path from './routes-path';

import Loading from '../common/loading/loading';
export default class AuthenticateRoute extends React.Component {

    state = {
        isLoading: true
    }

    isAuthenticated() {
        if (SessionCache.isAuthenticated() == null) {
            Service.getData(Urls.IS_LOGGEDIN, {}, (err, res) => {
                if (res) {
                    SessionCache.setUser(res.data);
                    this.setState({
                        ...res.data,
                        isLoading: false
                    });
                } else {
                    this.setState({ isLoading: false })
                    SessionCache.setUser(null);
                }
            });
        }
        else {
            SessionCache.setUser(null);
            this.setState({
                isLoading: false
            });
        }
    }

    componentDidMount() {
        this.isAuthenticated();
    }

    render() {
        return (
            <div>
                {this.state.isLoading && <Loading></Loading>}
                {this.state.isLoading == false && SessionCache.isAuthenticated() && <Route {...this.props} component={this.props.component} />}
                {this.state.isLoading == false && SessionCache.isAuthenticated() == false && <Redirect to={Path.LOGIN} />}

            </div>
        );
    }

}