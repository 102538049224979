
export default class SessionCache {

    static user = null;

    static isAuthenticated() {

        if (this.user == null) {
            return null;
        }
        else if (this.user['isAuthenticated']) {
            return true;
        }

        return false;
    }

    static isVerified() {

        return false;
    }

    static getName() {
        if (this.user) {
            return this.user.name;
        }

        return "";
    }


    static getRole() {
        if (this.user) {
            return this.user.role;
        }

        return "";
    }

    static setUser(obj) {

        this.user = obj;
    }
};