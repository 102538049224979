import React from 'react';
import "./payment.scss";
import FailureImage from '../../assets/images/fail-image.png'
import Path from '../routes/routes-path';
import HeaderLogo from '../../assets/images/logo.png'
import ReactPixel from 'react-facebook-pixel';
import { Constants } from '../../constants/constants'
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import branch from 'branch-sdk'
import serverConfig from '../../apis/server-config'

const firebaseConfig = {
    apiKey: "AIzaSyBOQaznope_EJxUcCiXhdYUBpo9XbWEtgM",
    authDomain: "countingwell-22064.firebaseapp.com",
    projectId: "countingwell-22064",
    storageBucket: "countingwell-22064.appspot.com",
    messagingSenderId: "434404415565",
    appId: "1:434404415565:web:b521ffe4ee74a59479e404",
    measurementId: "G-9V6QN94W27"
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

branch.init("key_live_gnJZ4YlUm4C9IEJsxE5OwpgmqvagHTST", () => {

})


class PaymentFail extends React.Component {

    componentDidMount() {
        ReactPixel.init(Constants.FB_PIXEL_ID);
        ReactPixel.pageView()
        ReactPixel.track("Purchase", { value: 1, currency: "INR", content_name: "SUBSCRIPTION_FAILED" })

        let event = serverConfig.EVENT_PREFIX_TEXT ? `${serverConfig.EVENT_PREFIX_TEXT}${Constants.SUBSCRIPTION_FAIL_EVENT}` : Constants.SUBSCRIPTION_FAIL_EVENT

        try {
            firebase.analytics().logEvent(event, { type: 'WEB' });

        } catch (error) {
            console.log(error)
        }

        try {
            branch.logEvent(event, {
                type: 'WEB'
            })

        } catch (error) {
            console.log(error)
        }
    }

    onClickTry() {

        let search = window.location.search;
        let params = new URLSearchParams(search);
        let url = params.get('redirectUrl')
        let planId = params.get('plan')
        let cid = params.get('cid')

        if (url) {
            window.location.replace(url);
        } else if (planId) {
            let redirectPath = `${Path.USER_SUBSCRIBE}?plan=${planId}`
            if (cid) {
                redirectPath = `${redirectPath}&cid=${cid}`
            }
            this.props.history.replace(redirectPath)
        } else {
            alert("INVALID ACTION")
        }
    }

    render() {

        return (
            <div className="payment-response">
                <div className="payment-response-background">
                    <div className='header row no-gutters'>
                        <a href="https://countingwell.com"> <img alt="" className="logo" src={HeaderLogo} /> </a>
                    </div>
                    <div className="parent-box pt-5">
                        <div className="child-box">
                            <div className='cw-card text-center'>
                                <div className='cw-card-body cw-card-size'>
                                    <div>
                                        <div className="icon-size"><img src={FailureImage} /></div>
                                    </div>
                                    <div className="payment-response-name pt-2">Payment Failed</div>
                                    <div className="pt-2 text-1">Try Again to make the Payment</div>
                                    <div className="pt-2">
                                        <span className="cw-button button btn btn-md" onClick={() => this.onClickTry()}> TRY AGAIN</span>
                                    </div>

                                    <div className="pt-4 text-1">Reach out to <b>support@countingwell.com</b> for any help</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default PaymentFail;